//extended from dropzone plugin

.dropzone {

  border: 2px dashed $border-color;
  transition: background-color ease 0.2s;
  border-radius: 0.25rem;
  &:hover {
    background-color: $gray-100;
  }
}
