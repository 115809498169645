@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?15210636');
  src: url('../font/fontello.eot?15210636#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?15210636') format('woff2'),
       url('../font/fontello.woff?15210636') format('woff'),
       url('../font/fontello.ttf?15210636') format('truetype'),
       url('../font/fontello.svg?15210636#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?15210636#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-add-circle:before { content: '\e800'; } /* '' */
.icon-add_plus:before { content: '\e801'; } /* '' */
.icon-add_user:before { content: '\e802'; } /* '' */
.icon-arrow:before { content: '\e803'; } /* '' */
.icon-ascend:before { content: '\e804'; } /* '' */
.icon-attachment:before { content: '\e805'; } /* '' */
.icon-attachment_v2:before { content: '\e806'; } /* '' */
.icon-bell:before { content: '\e807'; } /* '' */
.icon-camera:before { content: '\e808'; } /* '' */
.icon-cancel:before { content: '\e809'; } /* '' */
.icon-case:before { content: '\e80a'; } /* '' */
.icon-case_v2:before { content: '\e80b'; } /* '' */
.icon-chat:before { content: '\e80c'; } /* '' */
.icon-checkbox-selected:before { content: '\e80d'; } /* '' */
.icon-clipboard_report:before { content: '\e80e'; } /* '' */
.icon-clock:before { content: '\e80f'; } /* '' */
.icon-close:before { content: '\e810'; } /* '' */
.icon-contract:before { content: '\e811'; } /* '' */
.icon-date:before { content: '\e812'; } /* '' */
.icon-document-verified:before { content: '\e813'; } /* '' */
.icon-filte_3:before { content: '\e814'; } /* '' */
.icon-download_2:before { content: '\e815'; } /* '' */
.icon-down:before { content: '\e816'; } /* '' */
.icon-edit:before { content: '\e817'; } /* '' */
.icon-edit_2:before { content: '\e818'; } /* '' */
.icon-expand-arrows:before { content: '\e819'; } /* '' */
.icon-factory:before { content: '\e81a'; } /* '' */
.icon-filter:before { content: '\e81b'; } /* '' */
.icon-filter_2:before { content: '\e81c'; } /* '' */
.icon-flag:before { content: '\e81d'; } /* '' */
.icon-folder:before { content: '\e81e'; } /* '' */
.icon-form-checkmark:before { content: '\e81f'; } /* '' */
.icon-form-folder:before { content: '\e820'; } /* '' */
.icon-install-option:before { content: '\e821'; } /* '' */
.icon-line-chart:before { content: '\e822'; } /* '' */
.icon-link-previous:before { content: '\e823'; } /* '' */
.icon-menu:before { content: '\e824'; } /* '' */
.icon-menu_2:before { content: '\e825'; } /* '' */
.icon-minus:before { content: '\e826'; } /* '' */
.icon-minus-circle:before { content: '\e827'; } /* '' */
.icon-navigate:before { content: '\e828'; } /* '' */
.icon-next:before { content: '\e829'; } /* '' */
.icon-note:before { content: '\e82a'; } /* '' */
.icon-previous:before { content: '\e82b'; } /* '' */
.icon-projects:before { content: '\e82c'; } /* '' */
.icon-search:before { content: '\e82d'; } /* '' */
.icon-sort:before { content: '\e82e'; } /* '' */
.icon-status:before { content: '\e82f'; } /* '' */
.icon-sync:before { content: '\e830'; } /* '' */
.icon-up:before { content: '\e831'; } /* '' */
.icon-upload_2:before { content: '\e832'; } /* '' */
.icon-database:before { content: '\e833'; } /* '' */
.icon-form-actions:before { content: '\e834'; } /* '' */
.icon-comment_chat:before { content: '\e835'; } /* '' */
.icon-images_gallery:before { content: '\e836'; } /* '' */
.icon-group_users:before { content: '\e837'; } /* '' */
.icon-dislike:before { content: '\e83a'; } /* '' */
.icon-exclamation_mark:before { content: '\e83b'; } /* '' */
.icon-upload:before { content: '\e83c'; } /* '' */
.icon-user:before { content: '\e83d'; } /* '' */
.icon-map_pin:before { content: '\e83e'; } /* '' */
.icon-share:before { content: '\e83f'; } /* '' */
.icon-workshop:before { content: '\e840'; } /* '' */
.icon-like:before { content: '\e841'; } /* '' */
.icon-doc_pdf:before { content: '\e842'; } /* '' */
.icon-announce:before { content: '\e843'; } /* '' */