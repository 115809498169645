// css switch
.cstm-switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  .cstm-switch-input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &.disabled ~ .cstm-switch-indicator,
    &[disabled] ~ .cstm-switch-indicator {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:not(:checked) ~ .cstm-switch-indicator {
      background: $gray-300;
    }
    &:checked ~ .cstm-switch-indicator {
      background: $primary;
    }

    &:checked ~ .cstm-switch-indicator:before {
      left: calc(1rem + 1px);
    }
    &:checked ~ .cstm-switch-indicator.size-lg:before {
      left: calc(1.7rem + 1px)
    }

  }
  .cstm-switch-indicator {
    display: inline-block;
    height: 1.25rem;
    width: 2.25rem;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid $border-color;
    transition: .3s border-color, .3s background-color;
    &:before {
      content: '';
      position: absolute;
      height: calc(1.25rem - 4px);
      width: calc(1.25rem - 4px);
      top: 1px;
      left: 1px;
      background: #fff;
      border-radius: 50%;
      transition: .3s left;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    &.size-lg {
      display: inline-block;
      height: 2.25rem;
      width: 4rem;
      border-radius: 50px;
      position: relative;
      vertical-align: bottom;
      border: 1px solid $border-color;
      transition: .3s border-color, .3s background-color;

    }

    &.size-lg:before {
      content: '';
      position: absolute;
      height: calc(2.25rem - 4px);
      width: calc(2.25rem - 4px);
      top: 1px;
      left: 1px;
      background: $white;
      border-radius: 50%;
      transition: .3s left;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }
  }
  .cstm-switch-description {
    margin-left: .5rem;

    transition: .3s color;
  }

}


