.dz-upload-button{
    padding-bottom: 10px;
}

.S3FileUpload{
    margin: 13px;
    .dropzone{
        .btn-full{
            color: #fff;
            i{
                font-size: 30px;
            }
            padding: 15px;
            &:hover{
                padding: 15px;
            }
        }
        &--isActive{
            padding: 0px;
            margin: 0px;
            display: flex;
            border: none;
            align-items: center;
            justify-content: center;
            .btn-full{
                i{
                    font-size: 30px;
                }
                background: #fff;
                border: 1px solid #2e384d;
                color: #2e384d;
                padding: 15px;
                &:hover{
                    padding: 15px;
                }
            }
        }
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        .display-4{
            font-size: 30px;
            color: #b0b8cc;
        }
        span{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            &.heading{
                font-size: 16px;
                font-family: 'Rubik';
                color: #b0b8cc;
            }
            &.subheading{
                font-size: 11px;
                font-family: 'Rubik';
                color: #b0b8cc;
                small{
                    margin-left: 5px;
                    font-size: 11px;
                    font-family: 'Rubik';
                    color: blue;
                }
            }
        }
    }
}