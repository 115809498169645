//css tag style checkbox
.tag-input {
  display: inline-block;
  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  label {
    position: relative;
    padding: 8px 10px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    transition: all ease 0.2s;
    box-shadow: $box-shadow;
    cursor: pointer;
  }

  input[type="checkbox"]:checked + label {

    color: #fff;
    padding-left: 25px;
    background-color: $primary;
    &:before {
      left: 6px;
      position: absolute;
      font-family: $icon-font;
      font-size: 1rem;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: $icon-check;

    }
  }
  input[type="checkbox"]:active + label {
    background-color: $gray-300;
  }

}