.wizard-heading{
    font-size: 26px;
    text-align: center;
    width: 100%;
}
.CreateNewJobType-Wizard, .CreateNewEntity-Wizard {
    width: 95vw;
    height: 90vh;
    padding: 25px;
    overflow: auto;
    .h5{
        font-size: 1rem;
    }
    div[class*="rsw_"]{
        height: 100%;
        min-height: 350px;
    }
    .WhichEntity{
        width: 100%;
        height: 100%;
        min-height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        .circle{
            overflow: hidden;
            border-radius: 100%;
            width: 70px;
            height: 70px;
            margin-bottom: 8px;
            &.mdi-briefcase-outline {
                font-size: 2.5em;
            }
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .InfoRowContainer {
            display: flex;
        }
        .wizardCol{
            width: 300px;
            height: 300px;
            max-width: 300px;
            padding: 0px;
            margin: 0px;
            .option-box-grid{
                padding: 10px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.5s ease;
                &:hover{
                    background: rgba(46, 91, 255, 0.1);
                    cursor: pointer;
                }
                div{
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                }
                span{
                    font-family: 'Roboto', sans-serif;
                }
            }
            .option-box-grid-no-hover {
                &:hover{
                    background: unset;
                    cursor: unset;
                }
            }
        }
        .OptionBoxesContainer {
            display: flex;
            width: 100%;
            justify-content: center;
            .option-box {
                position: relative;
                width: 20%;
                margin: 2.5%;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgba(46, 91, 255, 0.1);
                .option-box-content {
                    text-align: center;
                }
                &:hover{
                    background: rgba(46, 91, 255, 0.1);
                    cursor: pointer;
                }
            }
        }
        
    }
}
.CreateNewEntity-Wizard {
    .CustomCheckbox{
        min-height: 56px;
    }
    input{
        border-radius: 0px !important;
    }
    .fullHeight{
        height: 100%;
    }
    .ModalBody{
        min-height: 350px;
        height: 100%;
        position: relative;
        width: 100%;

        flex-direction: column;
        .Autoselect ul:not(.Autoselect-list){
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
        .flatpickr-input{
            display: none;
        }
    }
}

@media (min-width: 768px){
    .CreateNewEntity-Wizard{
        overflow: hidden;
    }
    .LeftPanel-Wizard{
        align-items: center;
        justify-content: center;
        display: flex !important;
        ul{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            list-style: none;
            justify-content: center;
            li{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                .circle{
                    width: 35px !important;
                    height: 35px !important;
                    .mdi{
                        font-size: 32px;
                    }
                    text-align: center;
                }
                .icon{
                    margin-right: 10px;
                }
            }
        }
    }

    .CreateNewEntity-Wizard {
        .ListOptions {
            width: 60% !important;
        }
    }
}

@media (max-width: 768px){
    .CreateNewEntity-Wizard, .CreateNewJobType-Wizard {
        .wizard-heading{
            font-size: 1rem;
        }
        height: calc(100% - 100px);
        padding: 0px;
        .ModalBody{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .WhichEntity{
            width: 100vw;
            height: calc(95vh - 170px);
            flex-direction: column;
            .wizardCol{
                display: flex;
                flex: 3;
                width: auto;
                height: auto;
            }
            .option-box-grid{
                padding: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                i{
                    font-size: 4rem;
                }
            }
        }
        .CreateJob {
            display: block;
        }
        .JobCreated, .CreateJob {
            flex-direction: row;
            .DoMore {
                margin: 10px;
                width: 100%;
                text-align: center;
            }
            .InfoRowContainer {
                flex-direction: column;
                width: 100%;
                .wizardCol{
                    height: 50px;
                    max-width: unset;
                    width: 90%;
                    margin: 2.5px 2.5%;
                    margin-bottom: 5px;
                    border: 1px solid rgba(46, 91, 255, 0.1);
                }
                .option-box-grid{
                    .circle{
                        width: 20%;
                        height: 50px;
                        margin-bottom: 0;
                        img{
                            width: 40px !important;
                            height: 40px !important;
                        }
                    }
                    i{
                        font-size: 3rem;
                        margin-bottom: 0px;
                    }
                    div{
                        width: 100%;
                        flex-direction: row !important;
                        justify-content: unset !important;
                        span{
                            text-align: left;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .OptionBoxesContainer {
                flex-direction: column;
                .option-box {
                    width: 90%;
                    height: 50px;
                    margin: 2.5px 2.5%;
                    justify-content: unset;
                    padding-left: 5px;
                    .option-box-grid {
                        width: 100%;
                        padding: 5px;
                    }
                    .option-box-content {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        i{
                            font-size: 2rem;
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                        span{
                            text-align: left;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .ListComponent {
            display: flex !important;
        }
    }
}

.ListOptions {
    height: 95%;
    overflow-y: auto
}

.ListWizard {
    flex: 10;
    height: 90%;
    margin-top: 10px;
    align-items: center !important
}

.ListComponentAvatar-Wizard {
    height: 54px !important;
    align-items: center;
    display: flex;
    padding-bottom: 7px;
    margin-left: 20px;
}

.IconAvatar {
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.with-search-button-icon {
    cursor: pointer;
    &:hover {
        color: grey !important
    }
}