.FileManager {
  .file-image {
    flex: 1;
    img {
      object-fit: cover;
      width: 50px;
      height: 50px;
      //background-color: #2B384F;
      color: #fff;
    }
  }

  .file-image {
    text-align: center;
    i {
      font-size: 30px;
    }
  }

  .file-accordeon {
    height: 46px;
    display: flex;
    align-items: center;
    cursor: pointer
  }

  .file-actions {
    font-size: 30px;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    position: relative;
  }

  .file-actions-list {
    display: none;

    &.show {
      display: block;
      position: absolute;
      top: 0;
      z-index: 99;
    }
  }

  .list-files {
    li {
      background: #fff;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      flex-wrap: nowrap;
      flex-direction: column;
      width: 100%;
      font-size: 12px;
      color: #8798ad;
      padding: 15px;
      border-bottom: 1px solid;
      border-color: rgba(0, 0, 0, 0.42);
      .file-content {
        display: flex;
        align-items: center;
        height: 46px;
        flex: 3;
        align-items: center;
        padding-left: 15px;
        word-wrap: break-word;
        h6 {
          font-size: 15px;
          color: #1b1d29;
          font-family: "Rubik";
          font-weight: 500;
          letter-spacing: 1.03px;
          word-wrap: break-word;
          padding: 5px;
          word-break: break-all;
          margin-bottom: 0;
        }
      }
    }
  }
}
