$rotate180: 180deg;
$borderRadius: 10px;

$breakpoint-desktop: 1440px;
$breakpoint-smallDesktop: 1280px;
$breakpoint-tablet: 1024px;
$breakpoint-tabletMaxHeight: 1366px;
$breakpoint-mobile: 768px;
$breakpoint-smallMobile: 540px;

$primary: #2e384d;
$secondary: #b0b8cc;

@mixin smallDesktop {
    @media only screen and (max-width: $breakpoint-smallDesktop) {
        @content; 
    }
}

@mixin ipad {
    @media only screen 
        and (min-device-width: $breakpoint-tablet) and (max-device-width: $breakpoint-tablet) and (min-device-height: $breakpoint-tabletMaxHeight) and (max-device-height: $breakpoint-tabletMaxHeight) and (min-width: $breakpoint-tabletMaxHeight) and (max-width: $breakpoint-tabletMaxHeight), 
        (min-device-width: $breakpoint-tablet) and (max-device-width: $breakpoint-tablet) and (min-device-height: $breakpoint-tabletMaxHeight) and (max-device-height: $breakpoint-tabletMaxHeight) and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-tablet) {

        @content; 
    }
}

@mixin galaxyTab {
    @media only screen 
        and (min-device-width: 1280px) 
        and (max-device-width : 1280px)
        and (max-device-height: 800px) 
        and (orientation : landscape) {
        
        @content; 
    }
}

@mixin mobile {
    @media only screen and (max-width: $breakpoint-mobile) {
        @content; 
    }
}

@mixin mobileDevices() {
    @include tablet() { @content }
    @include mobile() { @content }
}

@mixin tablet {
    @include ipad() { @content }
    @include galaxyTab() { @content }
    @media only screen 
        and (min-width: $breakpoint-mobile) 
        and (max-width: $breakpoint-tablet) {

        @content; 
    }
}