html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal;
}

ul {
	list-style: none;
}

button,
input,
select,
textarea {
	margin: 0
}

html {
	box-sizing: border-box
}

*,
*:before,
*:after {
	box-sizing: inherit
}

img,
embed,
object,
audio {
	height: auto;
	max-width: 100%
}

iframe {
	border: 0
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

td,
th {
	padding: 0;
	text-align: left
}

body {
  font-family: Roboto, Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
}

.app__layout {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.app__header {
  width: 100%;
  height: 56px;
  color: #fff;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.app__header-icon {
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.app__header-icon:active {
  opacity: 0.8;
}

.app__header-title {
  margin-left: 5px;
  font-size: 19px;
  user-select: none;
}

.app__layout-content {
  height: inherit;
  /*margin-top: 56px;*/
}

.custom-menu-icon {
  font-size: 28px;
  line-height: 47px;
}

.custom-title,
.custom-menu-icon {
  color: #fff;
}

.custom-btn {
  position: fixed;
  right: 26px;
  bottom: 26px;
  background: #448aff;
  border-radius: 50%;
  border: none;
  width: 56px;
  height: 56px;
  outline: none;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  z-index: 9999;
}

.custom-btn:active {
  box-shadow: none;
}

.custom-msg {
  text-align: center;
  width: 90%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 16px;
}

.custom-fab-icon {
  color: #fff;
  font-size: 30px;
  margin-top: 2px;
  user-select: none;
}

video {
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
}

#list li {
  list-style-type: none;
  text-decoration: underline;
  color: #00F;
}

.custom-copy-btn {
  opacity: 0;
}

.hide {
  display: none;
}

@-webkit-keyframes scanner {
  0% {
    bottom: 90%;
  }
  50% {
    bottom: 10%;
  }
  100% {
    bottom: 90%;
  }
}

@-moz-keyframes scanner {
  0% {
    bottom: 90%;
  }
  50% {
    bottom: 10%;
  }
  100% {
    bottom: 90%;
  }
}

@-o-keyframes scanner {
  0% {
    bottom: 90%;
  }
  50% {
    bottom: 10%;
  }
  100% {
    bottom: 90%;
  }
}

@keyframes scanner {
  0% {
    bottom: 90%;
  }
  50% {
    bottom: 10%;
  }
  100% {
    bottom: 90%;
  }
}

.custom-scanner {
  width: 270px;
  height: 2px;
  background: #4CAF50;
  position: absolute;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-animation: scanner 3s infinite linear;
  -moz-animation: scanner 3s infinite linear;
  -o-animation: scanner 3s infinite linear;
  animation: scanner 3s infinite linear;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.4);
  display: none;
  left: -10px;
  right: 0;
  margin: auto;
}

#camera {
  opacity: 0;
}

.no-support {
  font-size: 20px;
  text-align: center;
}

.app__snackbar {
  position: fixed;
  bottom: 15px;
  left: 20px;
  pointer-events: none;
  z-index: 9999;
}

.app__snackbar-msg {
  width: 250px;
  min-height: 50px;
  background-color: #404040;
  color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 10px;
  word-break: break-all;
  -webkit-transition: opacity 3s cubic-bezier(0, 0, 0.30, 1) 0;
  transition: opacity 0.30s cubic-bezier(0, 0, 0.30, 1) 0;
  text-transform: initial;
  margin-bottom: 10px;
  z-index: 9999;
}

.app__snackbar--hide {
  opacity: 0;
}

.app__infodialog,
.app__dialog {
  z-index: 12;
  background-color: #fff;
  width: 290px;
  height: 180px;
  border-radius: 8px;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  box-shadow: 0 9px 46px 8px rgba(0,0,0,.14), 0 11px 15px -7px rgba(0,0,0,.12), 0 24px 38px 3px rgba(0,0,0,.2);
}

.app__infodialog {
  max-width: 480px;
  width: 95%;
  height: 240px;
}

.app__dialog-content,
.app__infodialog-content {
  width: 100%;
  padding: 15px;
}

.app__infodialog-subcontent {
  margin-top: 18px;
}

.app__infodialog-subcontent a {
  color: #1b1eb5;
}

.app__infodialog-subcontent p:not(:first-child) {
  margin-top: 10px;
}

.app__dialog-content h1,
.app__infodialog h1 {
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 19px;
}

.app__dialog input {
  width: 250px;
  height: 30px;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.24);
  outline: none;
  font-size: 15px;
  color: #1b1eb5;
  font-weight: 500;
}

.app__dialog-actions,
.app__infodialog-actions {
  display: block;
  position: absolute;
  bottom: 13px;
  right: 20px;
}

.app__infodialog-open,
.app__infodialog-close,
.app__dialog-open,
.app__dialog-close {
  border: 0;
  height: 35px;
  width: 70px;
  font-size: 16px;
  background: transparent;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  margin-right: -15px;
}

.app__dialog-open {
  display: none;
}

.app__infodialog-open:active,
.app__infodialog-close:active,
.app__dialog-open:active,
.app__dialog-close:active {
  opacity: 0.9;
}

.app__infodialog--hide,
.app__dialog--hide {
  display: none;
}

.app__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 200ms ease-in;
  width: 310px;
  height: 310px;
  margin: auto;
}


.app__overlay-left,
.app__overlay-right {
  width: 52px;
  height: 340px;
  background: #7f7f7f;
}

.app__overlay-left {
  margin-left: -57px;
  margin-top: -10px;
}

.app__overlay-right {
  margin-right: -57px;
  margin-top: -340px;
  float: right;
}

.app__overlay {
  border: 0;
}

.app__help-text,
.app__select-photos {
  color: #fff;
  position: absolute;
  bottom: -70px;
  font-size: 18px;
  right: 0;
  text-align: center;
  user-select: none;
}

.app__help-text {
  display: none;
  left: 0;
}

.app__dialog-overlay,
.app__infodialog-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.55);
  z-index: 11;
}

.camera__icon,
.focus__icon {
  position: relative;
  left: 10px;
  display: none;
}

.app__select-photos {
  width: 58px;
  height: 58px;
  cursor: pointer;
  position: fixed;
  bottom: 24%;
  right: 20px;
  border-radius: 50%;
  background-color: #3F51B5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app__select-photos:active {
  opacity: 0.8;
}

input[type='file'] {
  display: none;
}

#frame {
  width: auto;
  height: auto;
}

.app__version {
  position: absolute;
  bottom: 20px;
  font-size: 12px;
  color: #737373;
  margin-top: 15px;
  display: inline-block;
}

.app__scanner-img {
  z-index: 1;
  position: relative;
  display: none;
}

@media screen and (max-width: 480px) {
  .app__infodialog {
    width: 90%;
  }

  .app__infodialog-content {
    padding: 12px 13px;
  }
}