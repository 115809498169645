// Spacing Mixins

//Padding  Classes
@mixin generate-padding-options-prefix($n, $j: 0) {
  @if $j <= $n {
    @for $i from $j through $n {
      $step: $i*5;
      .p-t-#{$step} {
        padding-top: ($step*1px);
      }
      .p-r-#{$step} {
        padding-right: ($step*1px);
      }
      .p-l-#{$step} {
        padding-left: ($step*1px);
      }
      .p-b-#{$step} {
        padding-bottom: ($step*1px);
      }

    }
  }
}

//Margins  Classes
@mixin generate-margin-options-prefix($n, $j: 0) {
  @if $j <= $n {
    @for $i from $j through $n {
      $step: $i*5;
      .m-t-#{$step} {
        margin-top: ($step*1px);
      }
      .m-r-#{$step} {
        margin-right: ($step*1px);
      }
      .m-l-#{$step} {
        margin-left: ($step*1px);
      }
      .m-b-#{$step} {
        margin-bottom: ($step*1px);
      }
    }
  }
}