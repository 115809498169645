.BackButton{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
    .btn-back {
        font-size: 40px;
    }
}
