//extended from apexcharts styling

.apexcharts-canvas {
  margin: auto;

  height: 300px;
  .apexcharts-title-text, .apexcharts-legend-text, .apexcharts-legend-series, .apexcharts-xaxis-label, .apexcharts-yaxis-label, .apexcharts-yaxis-title, .apexcharts-xaxis-title, text {
    font-family: $font-primary;
    fill: $body-color !important;
    font-weight: 600;
  }

}

//Apexcharts invert Colors for dark background

.invert-colors {
  .apexcharts-title-text, .apexcharts-legend-text, .apexcharts-legend-series, .apexcharts-xaxis-label, .apexcharts-yaxis-label, .apexcharts-yaxis-title, .apexcharts-xaxis-title {

    fill: rgba(255, 255, 255, 0.57) !important;

  }
  .apexcharts-zoom-icon svg, .apexcharts-zoom-in-icon svg, .apexcharts-zoom-out-icon svg, .apexcharts-reset-zoom-icon svg, .apexcharts-download-icon svg {
    fill: rgba(255, 255, 255, 0.57) !important;

  }
}

