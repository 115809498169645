.Autoselect {
    .react-autosuggest__suggestions-list {
        border: 1px solid #ccc;
    }

    .input-group{
        border: 1px solid #000;
        .input-group-text{
            border: none;
            box-shadow: none;
        }
        input{
            border: none;
            outline: none;
            width: 85%;
        }
        i{
            font-size: 18px;
        }
        .with-search-button {
            width: 15%;
        }
        .with-search-button-icon {
            cursor: pointer;
            &:hover {
                color: grey !important
            }
        }
    }

    .no-suggestions {
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
        border-top: 1px solid #979797;
        margin-top: 10px;
        span {
            color: #979797;
            padding: 10px;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    li {
        list-style-type: none;
        display: block;
        padding: 7px 15px;
        position: relative;
    }

    &-user-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        @media (max-width: 997px) {
            width: 80px !important;
            height: 70px !important;
        }
        i{
            width: 30px !important;
            height: 30px !important;
        }
    }

    input {
        display: block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #3e4676;
        background-color: #ffffff;

        &:focus {
            color: #495057;
            background-color: #fff;
        }
    }

    &-list {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        li {
            background: #fff;
            border: 1px solid #fff;
            padding: 0;
            margin-bottom: 10px;
            color: #ABABAB;
            cursor: pointer;
            p{
                margin-bottom: 0px;
                padding-bottom: 0px;
                margin-top: 5px;
            }
            &.isNew{
                border: 1px solid #ccc;
                background: #D3D8E4;
                color: #408ff8 !important;
                .circle,
                i.large,
                span,
                p {
                    color: #408ff8;
                }
            }
            .not-an-user{
                .Autoselect-user-avatar{
                    margin-left: 10px;
                    margin-top: 2.5px;
                }
            }
            
            &:hover{
                border: 1px solid #408ff8;
            }
        }
    }

    .delete {
        background: #df6767;
        color: #fff;
        height: 25px;
        display: flex;
        width: 100px;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1;
    }

    .Autoselect-item,
    .Autoselect-user {
        padding: 3px 0px 3px 45px;
        height: 80px;
        line-height: 80px;
        font-size: 11px;
        line-height: 1;
        color: #979797;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img.circle{
            width: 34px !important;
            height: 34px !important;
            border: 6px solid #1f2532;
        }
        &.not-an-user{
            justify-content: flex-start;
            .Autoselect-user-avatar{
                display: flex;
                align-items: center;
                justify-content: center;
                .mdi{
                    font-size: 22px !important;
                }
                img{
                    width: 30px;
                    height: 30px;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
        &-content {
            display: flex;
            align-items: center;
            word-break: break-word;
            @media (max-width: 997px) {
                justify-content: space-around;
                width: 100%;
            }
        }

        &-name {
            margin-left: 20px;
            word-break: break-all;
        }

        i.large {
            left: 20px;
            font-size: 24px;
            color: #979797;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }

        .circle {
            color: #fff;
            position: relative;
            width: 34px !important;
            height: 34px !important;
            font-size: 26px;
            
            box-sizing: content-box;
            text-align: center;
            display: inline-block;
            border-radius: 100%;
            background: #1f2532;
            border: 6px solid #fff;
            &:before {
                transform: translate(-50%, -50%);
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
            }
        }
    }

    .Autoselect-item {
        padding: 10px 50px 10px 60px;
    }
    
    .green{
        background-color: #43ea00;
    }
    .cstm-switch{
        float: right;
        margin-bottom: 15px;
        .cstm-switch-description{
            margin-right: .5rem;
        }
    }

    @media (max-width: 997px) {
        .Autoselect-list {
            .Autoselect-user {
                &-avatar {
                    width: 80px !important;
                    height: 70px !important;
                    img{
                        width: 70px !important;
                        height: 70px !important;
                    }
                    i{
                        width: 70px !important;
                        height: 60px !important;
                    }
                }
            }
        }
    }
}
