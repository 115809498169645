@import "../../../variables";
.adminLayout {
  position: relative;
  .create-new {
    background: #0083c9;
    &:hover {
      span {
        color: #0083c9 !important;
      }
    }
    span {
      color: #fff !important;
    }
  }
  .admin-sidebar {
    top: 66px;
    height: calc(100% - 66px);
    overflow: initial;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(42, 76, 133, 0.15);

    .admin-sidebar-brand {
      margin-bottom: 20px;
      background-color: transparent;
      li:hover {
        cursor: pointer;
      }
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      .admin-brand-content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .admin-sidebar-wrapper {
      overflow: initial;
      height: 90%;
      .menu {
        height: 100%;
        .menu-item {
          height: 50px;
          &.create-new {
            .menu-name {
              color: #32a1d6;
            }
          }
          &.sign-out {
            .menu-name {
              color: #df6767;
            }
          }
          &.user {
            span {
              color: #9b9b9b;
              font-size: 12px;
              letter-spacing: 1.13px;
              font-family: "Roboto";
              text-transform: uppercase;
            }
            img {
              width: 35px;
              height: 35px;
              object-fit: cover;
              margin-right: 11px;
            }
          }
          &:hover {
            .sub-menu {
              display: block !important;
            }
            background: #e9eeff;
          }

          &.active {
            background: rgba(#2e5bff, 0.1);
          }
          .menu-link {
            a {
              padding: 5px 15px;
            }
            small {
              width: 100%;
              color: #9b9b9b;
            }
            .menu-label {
              color: #9b9b9b;
              font-size: 12px;
              text-transform: uppercase;
              font-weight: 400;
            }
            .menu-icon {
              color: #9b9b9b;
              background: none;
              .icon-placeholder {
                color: #9b9b9b;
                font-size: 25px;
              }
            }
          }
        }

        .sub-menu {
          background: #e9eeff;
          position: absolute;
          top: 0;
          left: 100%;
          width: 100%;
          z-index: 1111;
          overflow: hidden;
          overflow-y: scroll;
          max-height: 300px;

          li {
            &:hover {
              background: #e9eeff !important;
            }
            position: relative;
            &:not(.create-new) {
              background: #fff;
            }
            &:first-child:before {
              content: "";
              position: absolute;
              top: 0;
              left: -8px;
              z-index: 10;
              background: #2e5bff;
              width: 3px;
              height: 100%;
              display: block;
            }
          }
        }
      }
    }
  }

  @include mobile() {
    .admin-sidebar {
      overflow: auto;
      top: 65px;

      .sub-menu {
        left: 0 !important;
        top: 50px !important;
        .menu-icon {
          float: left
        }
      }
      &.sidebar-show {
        transform: translateX(0) !important;
      }
      .font-size-25 {
        font-size: 25px
      }
    }
  }

  .jobTypeIcon {
    img {
      width: 25px;
      height: 25px;
      border-radius: 100%;
    }
  }
}

@media (min-width: 992px) {
  .adminLayout {
    position: relative;
    .admin-sidebar {
      width: 82px;
      transition: width 0.5s;
      .sub-menu {
        margin-top: -25vh;
        max-height: 50vh !important;
        width: 240px !important;
        padding-left: 0px !important;
        .menu-label {
          display: flex;
          flex-direction: row !important;
          align-items: center;
          justify-content: flex-start !important;
          .menu-icon {
            font-size: 25px;
          }
        }
      }
      .sub-menu-apps {
        margin-top: 0;
        top: unset !important;
        bottom: 0 !important;
        overflow-y: unset !important;
        box-shadow: 0 2px 4px 0 rgba(42, 76, 133, 0.15);
      }
      .menu {
        .menu-item {
          //height: 70px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            padding: 0px;
            align-items: center;
            justify-content: center;
            .menu-label {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              span {
                margin: 0px;
                text-align: center;
              }
            }
          }
        }
      }
      &.sidebar-show {
        width: 240px;
        .menu {
          .menu-item {
            height: 70px;
            display: flex;
            flex-direction: row;
            a {
              display: inline-flex;
              flex-direction: row;
              justify-content: flex-start;
              padding: 0px 10px;
              .menu-label {
                width: auto !important;
                align-items: center;
                display: flex;
                flex-direction: row;
                span {
                  height: 100% !important;
                  display: inline-flex !important;
                  align-items: center !important;
                  &.menu-name {
                    line-height: 40px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
