@import "../../variables";
.question {
  text-align: center;
  font-size: 16px;
  min-height: 100%;
  height: 100%;
  position: relative;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 60px);
    overflow-x: auto;
    padding-bottom: 180px;
    .answer-action-toolbar {
      display: flex;
      flex-direction: row;
      width: 100%;
      background: rgba(255, 255, 255, 0.3);
      height: 50px;
      align-items: center;
      justify-content: center;
      .action-toolbar-tab {
        width: 33.3%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
        }
        i {
          color: #b0bac9;
          font-size: 30px;
          &.mdi-comment {
            color: #7aed9f;
          }
          &.mdi-image-multiple {
            color: #269ce0;
          }
        }
      }
    }
    .file-is-required {
      display: flex;
      width: 100%;
      justify-content: center;
      color:red;
      font-size: 1.2rem
    }
  }

  .fileIcon {
    font-size: 30px !important;
    &.mdi-folder-multiple-image {
      color: #269ce0 !important;
    }
  }

  .files-overview {
    margin-top: 5px;
    .preview {
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }
    p {
      font-family: "Rubik";
      font-weight: 300;
      font-size: 12px;
      text-align: right;
    }
    width: 100%;
  }

  .shell {
    max-width: 500px;
    margin: 0 auto;
  }

  p {
    margin: 0;
  }

  &-header {
    background: #fff;
    width: 100%;
    color: #2e384d;
    height: 60px;
    text-align: center;
    padding: 10px;
    //box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.13);
    padding-left: 0px;
    padding-right: 0px;
    border-bottom: 1px solid #e4e8f0;
    div {
      float: left;
    }
    .back-btn {
      width: 5%;
      text-align: left;
      padding-left: 10px;
      a {
        font-size: 30px;
        font-weight: 200;
      }
    }
    .title {
      width: 80%;
      font-family: "Rubik";
      font-weight: 300;
      font-size: 20px !important;
      line-height: 30px;
      padding-top: 17px;
      text-align: center;
      height: 71% !important;
      text-overflow: ellipsis;
      display: inline-flex !important;
      align-items: center !important;
      padding-right: 20px;
    }
    .jobInfo {
      width: 15%;
      padding: 0 14px;
      .EditorView-time {
        width: 40px;
        height: 40px;
        float: right;
      }
    }

    @include mobile() {
      .title {
        width: 62%;
        font-family: "Rubik";
        font-weight: 400;
        font-size: 16px !important;
        padding-top: 14px;
        text-align: left;
        margin-left: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
      }
      .jobInfo {
        width: 15%;
        padding: 0 14px;
        .EditorView-time {
          width: 30px;
          height: 30px;
          float: right;
          margin-top: 10px;
        }
      }
    }
  }

  .issueButton {
    width: 100%;
    .issueBorder {
      border-width: 5px;
      background-color: #ffe8fb;
    }
    .toggler {
      margin-top: -1px;
      margin-right: 23px;
      height: 40px;
      width: 40px;
      float: right;
      text-align: center;
      background-color: #ffe8fb;
      color: #e942cc;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
      font-size: 25px;
    }

    &.toggled {
      .issueBorder {
        background-color: #e837c9;
      }
      .toggler {
        background-color: #e837c9;
        color: #fff;
      }
    }
  }

  &-title {
    max-width: 70%;
    text-align: center;
    padding: 0px 23px;
    padding-top: 13px;
    h5 {
      font-weight: 300;
      font-size: 36px;
      line-height: 54px;
      margin-top: 10px;
    }
    p {
      font-weight: 300;
      font-family: "Roboto", sans-serif;
      font-size: 22px;
      line-height: 33px;
      color: #b0bac9;
      min-height: 50px;
    }
  }

  &-content {
    width: 100%;
    max-width: 700px;
    margin: 20px auto;
    padding: 0 20px;

    &-close {
      margin-left: 10px;
      color: #99a3bd;
      font-size: 28px;
      cursor: pointer;
    }
  }

  &-settings {
    label {
      display: block;
    }
    padding: 0 20px;
  }

  &-actions {
    width: 99%;
    position: fixed;
    z-index: 9999;
    bottom: 0;
    //left: 0;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    padding-top: 5px;
    background: transparent;
    div {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: center;
      /* &:nth-child(2) {
        flex: 2;
      } */
    }
    .btn {
      font-size: 16px;
      border-radius: 3px !important;
      border: none;
      width: 15%;
      min-width: 150px;
      height: 55px;
      font-family: "Rubik", sans-serif;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: normal;
      color: #fff;
      text-transform: capitalize;
      &-primary {
        background-color: #2e384d;
      }
      /*  .btn {
        //border: none;
    //background: #EC4FA4;
    //font-size: 16px;
    //width: 15%;
    //height: 40px;
    //border-radius: 40px !important;
    //text-transform: capitalize;
    letter-spacing: normal;
      } */
    }
    .btn-back {
      background-color: #fff !important;
      color: #2e384d !important;
    }
  }

  .flatpickr-input {
    height: calc(2.25rem + 2px) !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3e4676;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #d3e0e9;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }



  .list-images {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    //flex-wrap: wrap;
    li {
      list-style-type: none;

      &.selected {
        border: 1px solid #007bff;
      }

      img {
        max-width: 200px;
      }
    }
  }

  /*  .fileList {
        list-style-type: none;
        padding-left: 0px;
        .file {
            .remove {
                float: right;
            }
        }
    }*/

  //short text
  .free-text {
    background-color: #f5f6fb;
    color: #2e384d;
    border: 0;
    font-size: 13px;
    font-family: "Rubik";
    padding: 20px;
    line-height: 20px;
    word-break: break-word;
    &:focus {
      background-color: #f5f6fb;
    }
    &:active {
      background-color: #f5f6fb;
    }
  }
  textarea.free-text {
    height: 250px;
  }
  //short text end

  //length

  .length-wrapper {
    .input-group {
      background-color: #f5f6fb;
      border-radius: 20px;
    }
    .free-text {
      height: 50px;
      text-align: center;
      font-size: 18px;
      color: #2e384d;
    }
  }

  .circle-button-length {
    width: 46px;
    height: 46px;
    border: solid 1px #e4e8f0;
    border-radius: 100%;
    text-align: center;
    padding-top: 5px;
    margin-top: 2px;
    background-color: #fff;
    span {
      font-size: 22px;
      color: #2e384d;
    }
  }

  //end length
}

.QuestionFormRenderer {
  .question-title-editor .public-DraftStyleDefault-block {
      white-space: nowrap !important;
    }
    
  p {
    margin: 0;
  }
  .card {
    &-header {
      padding: 0;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #2e384d;

      h4 {
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #2e384d;
        margin: 0;
      }

      h5 {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #b0b8cc;
        text-transform: uppercase;
        margin: 0;
      }
      &-title {
        background: #f5f6fb;
        padding: 0.82rem 1.25rem;
      }
      &-description {
        background: #fff;
        padding: 1.38rem 1.25rem;
        border-bottom: 1px solid #f5f6fb;
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .card-body {
      padding: 0;
      &-container {
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid #f5f6fb;
      }
    }
  }

  .form-group.float-label {
    label {
      min-width: 120px;
    }
  }
}

//Image component
.imageComponent {
  &.thumbs {
    .imgChoice {
      width: 40%;
      padding: 0 3.2px;
    }
    .heading-styled {
      padding: 0px;
    }
    .form-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .form-row {
      justify-content: center;
    }
    img {
      height: 80px;
    }
  }
  img {
    height: 233px;
    //object-fit: contain;
    width: 100%;
    opacity: unset !important;
  }
  .imgCmpCaption {
    color: #b0bac9;
  }
  .image-box {
    background-color: unset;
    display: flex;
    .image-box-content {
      width: 100%;
      position: relative;
      &:before {
        content: "";
      }
      &:after {
        content: "";
      }
      .imageSelected {
        position: absolute;
        width: 100%;
        height: 100%;
        //background-color: rgba(46, 56, 77, 0.8);
        top: 0;
        left: 0;
        font-size: 46px;
        color: #fff;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
  }
  .imgChoice {
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .heading-styled {
    padding: 0 10px;
    .mdi {
      margin-right: 6.4px;
    }
  }
  /*   @media (min-width: 768px) { */
  .form-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  /*   } */
}

//END Image component

//Bool component

.hide {
  display: none !important;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 28px;
}

.hideDiv {
  height: 50px;
  margin-bottom: 20px;
}

.BoolComponent-Yes:hover .hide {
  display: inline-block !important;
  //color: #2e384d;
}

.BoolComponent-No:hover .hide {
  display: inline-block !important;
  color: #8c94a0;
}

.BoolComponent-Wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
}

.BoolComponent {
  margin-top: 50px;
  width: 500px;
  height: 120px;
  display: flex;
  align-self: center;
  background-size: 125px 50px;
  background-repeat: no-repeat;
  .BoolComponent-Part {
    flex: 1;
    display: flex;
    background-size: contain;
    width: 99px;
    height: 52px;
    background-repeat: no-repeat;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    span {
      color: "#B0BAC9";
      text-transform: uppercase;
      font-family: "Roboto";
      font-size: 20px;
    }
    &.selected {
      /* span {
        color: green;
      } */
      .hide {
        display: inline-block !important;
      }
    }
  }
  .BoolComponent-Or {
    background-position: left top;
  }
  .BoolComponent-Yes {
    height: 120px;
    flex-direction: column;
    color: #8c94a0;
    //flex: 2;
    background-position: right top;
    margin-left: 50px;
    &:hover {
      color: #8c94a0 !important;
      cursor: pointer;
    }
    span {
      //width: 100%;
      display: inline-block;
      text-align: left;
      font-size: 28px;
    }
  }
  .BoolComponent-No {
    height: 120px;
    flex-direction: column;
    color: #8c94a0;
    //flex: 2;
    background-position: left top;
    margin-right: 50px;
    &:hover {
      color: #8c94a0 !important;
      cursor: pointer;
    }
    span {
      //width: 100%;
      display: inline-block;
      text-align: right;
      font-size: 28px;
    }
  }
}
//END bool component

//List component

.ListComponent {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 30px;
  &:hover {
    background-color: inherit;
  }
  .selected-answer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
  ul {
    display: flex;
    //flex: 1;
    width: 100%;
    //height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    li {
      width: 50%;
      margin: 5px auto;
      display: flex;
      align-items: center;
      min-height: 44px;
    }
    /* li:after:not(:first-child):not(:last-child) {
      border-bottom: 1px solid red;
    } */
  }
  &-AvatarCircle {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
    margin-top: 7px;
    background-color: #e4e8f0;
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }
  &-Search {
    margin-bottom: 15px !important;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #f5f6fb;
    //height: 36px !important;
    padding: 0px;
    margin-bottom: 10px;
    input {
      flex: 7;
      height: 54px;
      background-color: rgb(251, 251, 251);
      box-shadow: 0 !important;
      border: 0;
      &:focus {
        outline: none;
      }
    }
    input[placeholder] {
      padding-left: 20px;
      color: #b0bac9;
    }
    .input-group-append {
      flex: 1;
      height: 54px;
      align-items: flex-end;
      justify-content: flex-end;
      background-color: rgb(251, 251, 251);
      .input-group-text {
        background-color: rgb(251, 251, 251);
        border: 0;
        color: #b0bac9;
        font-size: 22px;
        line-height: 35px;
      }
    }
  }
  &-Avatar {
    width: 30px;
    height: 44px;
    margin-right: 19px;
    margin-left: 19px;
  }
  &-Value {
    //flex: 4;
    font-family: "Roboto";
    font-weight: 300;
    //color: #2e384d;
    //line-height: 32px;
    /* text-transform: uppercase; */
    padding-left: 20px;
    font-size: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* padding-left: 5px; */
  }
  &-Checked {
    /* flex: 1; */
    height: 44px;
    line-height: 44px;
    font-size: 20px;
    width: 60px;
    padding-left: 10px;
  }
}

//END List component

//Message Component

.MessageComponent {
  width: 70%;
  margin: 0 auto;
  padding: 25px 25px 58px 25px;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  &-Image {
    flex: 1;
    margin-bottom: 21px;
  }
  &-Text {
    flex: 1;
    font-size: 14px;
    //color: #313841;
    font-family: "Roboto";
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  img {
    height:300px;
    width: 500px
  }
}

//END Message Component

//Project value

.ProjectValue {
  padding: 0 30px;
  ul {
    list-style-type: none;
    text-align: left;
    li:not(.file) {
      font-family: "Rubik";
      font-weight: 300;
      color: #2e384d;
      min-height: 29px;
    }
  }
  &-Location {
    height: 400px;
  }
  &-Heading {
    flex: 1;
    text-align: left;
    font-size: 16px;
    text-transform: capitalize;
    line-height: 22px;
    font-family: "Rubik", sans-serif;
    font-weight: 600px;
    margin-bottom: 12px;
    i {
      font-size: 22px;
      color: #b0bac9;
    }
    span {
      color: #b0bac9;
      padding-left: 9px;
    }
  }
  &-Section {
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-Files {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

//END Project value

.BoolComponent {
  .BoolComponent-Yes {
    margin-left: 0;
  }
  .BoolComponent-No {
    margin-right: 0;
  }
}

//Bool

//END Bool

//Summary
.SummaryComponent {
  &-QuestionList {
    .step {
      display: flex;
      font-size: 1.7rem;
      padding: 13px 0px;
    }
    .JobPanelQuestion-answer {
      text-align: left;
    }
    .JobPanelQuestion-job-link {
      text-align: left;
      margin-top: 10px;
      h6 {
        max-width: 300px;
        flex-wrap: wrap;
      }
    }
  }
}
//END Summary

.question-toolbar-sticky {
  /* position: fixed;
  top: 60px; */
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .answer-action-toolbar .action-toolbar-tab {
    width: 30%;
    border-top: 3px solid #2e384d;
  }

}

.toolbar-bottom {
  position: absolute;
  bottom: 80px;
  z-index: 9999;
}

.commentSection {
  width: 100%;
  flex: 1;
  background-color: #f3f3f3;
  .commentToolbar {
    width: 100%;
    padding: 0px 23px;
    .commentSectionCol {
      font-size: 23px;
      color: #b0bac9;
      width: 50%;
      display: inline-block;
      &.hasComment {
        i,
        span {
          color: #e942cc !important;
        }
      }
      &.left {
        text-align: left;
        i {
          font-size: 18px;
        }
        span {
          color: #b0bac9;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          display: inline-block;
          margin-left: 10px;
          text-transform: uppercase;
        }
      }
      &.right {
        font-size: 35px;
        text-align: right;
      }
    }
  }
  .commentTextarea {
    padding: 0px 10px;
    width: 100%;
    display: none;
    canvas {
      background-color: #fff;
    }
    textarea {
      //border: 1px solid #b0bac9;
      border: none;
      color: #2e384d;
      font-family: "Rubik";
      font-size: 16px;
      background-color: #7aed9f;
      border-radius: 3px;
      resize: none;
      box-shadow: 0;
      line-height: 40px;
      height: 55px;
    }
    &.visible {
      display: inline-block;
    }
    .responseOptions {
      display: flex;
    }
    .emailTextArea {
      background: #A29BFE;
      height: auto;
      line-height: normal;
    }
  }
}

.emailSignatureCommentSection {
  background-color: white;
  border: 1px solid #f3f3f3;
}

.RangeDatePicker {
  display: flex;
  flex-direction: column;
  width: 100%;
  .flatpickr-input {
    cursor: pointer
  }
  .flatpickr-input:not([altinputclass="clean"]),
  .flatpickr-input:focus:not([altinputclass="clean"]) {
    border: none !important;
    outline: none;
    text-align: center;
  }
  p {
    margin-bottom: 10px;
    text-align: left;
    .flatpickr-input {
      float: right;
    }
  }
  .row {
    align-items: flex-end;
    justify-content: center;
  }
}

.row-all-day {
  justify-content: center;
}

.all-day-wrapper {
  max-width: 250px;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.no-background {
  background: transparent;
  textarea {
    border-color: #7aed9f !important;
  }
}

.editor {
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  cursor: text;
  padding: 8px;
  border-radius: 2px;
  //background: #f5f6fb;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3d5170;
  width: 80%;
  display: flex;
  align-items: center;
  .DraftEditor-root {
    width: 100%;
  }
}

.options {
  //background: #f5f6fb;
  height: 45px;
  display: flex;
  align-items: center;
}

.beige-backgroiund {
  background: #f5f6fb;
}

.grey-border {
  border: 1px solid #d3e0e9;
  border-radius: 5px;
  padding-bottom: 2px;
  .editor {
    height: auto;
  }
}

.editor-wrapper {
  width: 70%;
  height: auto;
  display: flex;
  border: 2px solid transparent;
  border-radius: 5px;
  &:hover {
    border: 2px solid #005fcc !important;
  }
}

.text-area-editor {
  resize: both;
  overflow: auto;
  height: 100px !important; // whatever height you want
  width: 100%;
  .editor {
      height: 100%;
      width: 100%;
  }
}

.input-focused {
  border: 2px solid #005fcc !important;
  border-radius: 5px;
}

.card-row {
  width: 100%;
}

.label {
  width: 30%;
}

.step-icon {
  display: flex;
  justify-content: center;
  width: 10%;
  cursor: pointer;
  .dropzone {
    border: unset;
  }
  .StepIcon-image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);
    color: #3e4676;
  }
}

.uploadText {
  margin-left: 10px;
}

.punch-counter {
  margin-top: 20px;
  font-size: 2rem;
}

.punch-input {
  margin: 20px
}

@media (min-width: 997px) {
  .issueBorder {
    height: 10px !important;
  }

  .ListComponent {
    ul li {
      &:hover {
        background-color: #f5f6fb;
        border-radius: 3px;
        color: #2e384d;
      }
    }
  }
}

@media (max-width: 767px) {

  .QuestionFormRenderer {
    margin-top: 20px;
  }

  .question-toolbar-sticky {
    display: block;
    width: 90% !important;
  }

  /* .toolbar-bottom {
    bottom: 55px;
  } */

  .question-title {
    max-width: 100%;
  }

  .question-title h5 {
    font-size: 28px;
    line-height: 30px;
    margin-top: 10px;
  }

  .question-title p {
    font-weight: 300;
    font-size: 16px;
    line-height: 14px;
  }

  .question-header {
    padding-bottom: 5px;
    display: flex;
    flex-wrap: nowrap;
    position: sticky;
    top: 0;
    .back-btn {
      width: 10%;
    }
  }

  .question-container {
    height: 90%;
  }

  .question-actions {
    width: 100%;
    bottom: 0;
    padding: 0px;
    div {
      width: 50%;
    }
    .btn {
      width: 100%;
    }
  }

  .ListComponent {
    display: -webkit-box;
    ul li {
      width: 100%;
    }
    &-Value {
      font-size: 18px;
      padding-left: 10px;
    }
    &-Checked {
      width: 30px;
      font-size: 10px;
    }
    &-Search {
      input {
        font-size: 12px;
      }
      input[placeholder] {
        text-align: start;
        padding-left: 10px;
      }
    }
  }

  .BoolComponent {
    width: auto;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .editor-wrapper {
    width: 100%;
  }

  .MessageComponent {
    img {
      height:150px;
      width: 250px
    }
  }

  .btn-full-width {
    width: 100%;
    margin-bottom: 10px;
  }
}
