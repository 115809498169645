.logo{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px;
    object-fit: contain;
    .logoWatermark{
        width: 32px !important;
        height: 32px !important;
        position: absolute;
        left: 0;
        bottom: -15px;
        object-fit: contain;
        
        &.pull-right {
            right: 2.5px;
            top: 2.5px;
            left: unset;
            width: 64px !important;
            height: 64px !important;
        }
    }
}