.google-map{
    align-items: flex-start;
    display: flex;
    justify-content: center; 
    height: 100%;
    overflow: hidden;
    .map-wrapper{
        min-height: 200px;
        height: calc(94% -  50px);
        width: 100%;
    }
    .modeSelector{
        display: flex;
        flex-direction: row;
        width: 100%;
        .btn-full{
            flex: 3;
            height: 50px;
            border-radius: 0px;
            padding: 0px;
        }
    }
    
    .suggestion-item, .suggestion-item--active{
        padding: 0px 0px 3px 3px;
        height: 40px;
        line-height: 40px;
    }
}

@media (max-width: 992px){
    .google-map{
        &.blue-frame{
            padding: 0px;
        }
        .modeSelector{
            position: relative;
            z-index: 9998;
            margin-top: -22px;
            .btn-full{
                font-size: 12px;
                line-height: 25px;
            }
        }
    }
}

.react-actionsheet-menu-item{
    color: #007AFF;
}