.badge{
    color: #fff !important;
    border-radius: 15px !important;
    font-size: 14px;
    font-weight: bold;
    text-transform: lowercase;
    font-family: 'Rubik';
    &.noLabel {
        border-radius: 50%;
        display: inline-block;
    }
}