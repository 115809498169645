.filters-wrapper{
    .filter-action-title{
        border: none !important;
        border-color: #9b9b9b !important;
        flex-direction: column;
        width: 80px !important;
        padding: 10px;
        border-radius: 0px !important;
        i{
            font-size: 25px;
        }
        span{
            padding-left: 0px !important;
            display: inline-block;
            min-height: 13px;
            font-size: 12px;
        }
        i, span{
            color: #9b9b9b;
        }
        &:hover, &.active{
            background: rgba(46, 91, 255, 0.1) !important;
            span{
                color: #9b9b9b;
            }
            i{
                color: #9b9b9b;
            }
        }
    }
}

.filters{
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

@media (max-width: 997px){

    .SearchField{
        &_icon{
            flex: 1;
            padding: 0px;
        }
        &_text{
            flex: 9;
        }
    }
    .FiltersModal-filters-action{
        padding: 0px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        .btn{
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}

.btn-white{
    background: #fff;
    color: #2e384d;
    border: 1px solid #2e384d;
}

.btn-blue{
    background: #0083C9 !important;
    color: #fff;
}