// Borders

.no-border {
  border: 0 !important;
}

.border-white {
  border-color: $white-translucent !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-top-dashed {
  border-top-style: dashed !important;
}

.border-left-dashed {
  border-left-style: dashed !important;
}

.border-right-dashed {
  border-right-style: dashed !important;
}

.border-bottom-dashed {
  border-bottom-style: dashed !important;
}

.border-strong {
  border-width: 6px !important;
}