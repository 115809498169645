//For checkboxes without labels example used in mail app
.minicheckbox {
  margin: 0;
  position: relative;
  vertical-align: middle;

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0;
  }
  .custom-options-box {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border-radius: $border-radius;
    background-color: $black-translucent;
    &:before {
      font-family: $icon-font;
      content: $icon-check;
      opacity: 0;
      position: absolute;
      left: 0;
      border-radius: $border-radius;
      height: 1rem;
      width: 1rem;
      transition: .3s opacity;
      color: #fff;
      top: 0px;
      font-size: 11px;
      text-align: center;
    }
  }
  input[type="checkbox"]:checked ~ .custom-options-box {
    background-color: $primary;
    &:before {
      opacity: 1;
    }
  }
}