.NotificationPanel{
    background-color: #fff;
    padding: 15px;
}

.NotificationsModal_body {
    &_group {
        &_title {
            width: 155px;
            height: 24px;
            font-family: Rubik;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: normal;
            color: #b0b8cc;
            text-align: left;
            margin-bottom: 0px;
            margin-top: 10px;;
        }
        &_item {
            border-bottom: 1px solid rgba(160, 178, 184, 0.2);;
            padding: 10px 0px 5px 0px;

            &:last-child{
                border-bottom: 0px;
            }
    
            &_avatar {
                float: left;
                width: 54px;
                height: 53px;
                border-radius: 92px;
                background-color: #f4f6fc;
                margin-right: 6px;
    
            }
    
            &_message {
                float: right;
                text-align: left;
                cursor: pointer;
    
                p {
                    margin-bottom: 0px;
                    height: 32px;
                    font-size: 12px;
                    line-height: 16px;
                    word-wrap: break-word;
                    color: #414d54;
                    overflow: hidden;
                }
    
                span {
                    width: 81px;
                    height: 14px;
                    font-family: Rubik;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #313e47;
                }
            }
        }    
    }
    
}