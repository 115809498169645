.SearchField {
  &_icon {
    i {
      font-size: 40px;
      color: #cecece;
    }
  }

  &_text {
    input {
      width: 100%;
      height: 100%;
      padding: 10px;
      border: none;
      font-size: 18px;

      &:focus {
        outline: none;
      }
    }
  }
  .SearchBar{
    i, span{
        color: #9b9b9b;
    }
    input{
        color: #cecece;
        border-bottom: 1px solid #9b9b9b;
        height: 44px;
    }
    input::placeholder{
        color: #9b9b9b;
    }
    &:hover, &.active{
        cursor: pointer;
        i, span{
            color: #408ff8;
        }
        input::placeholder{
            color: #408ff8;
        }
        input{
            border-color: #408ff8;
        }
    }
}
}
