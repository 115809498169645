//layout
html, body, section, main, header {
  position: relative;
}

html, body {
  height: 100%;
}

.admin-main {
  padding-top: $page-padding-y;
}

.admin-header {
  display: flex;
  background: $white;
  box-shadow: $top-nav-shadow;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  align-items: center;
  height: $header-height;
  border-bottom: $top-nav-border-bottom-width solid $border-color;
  .sidebar-toggle {
    display: none;
    align-items: center;
    padding-left: 15px;
    //avoid overlapping of search icon
    margin-right: 15px;
    font-family: $icon-font;
    font-size: 24px;
    &:before {
      content: $icon-menu;
    }
  }

}

@media (min-width: 992px) {
  .admin-main, .admin-header {
    padding-left: 0px;
  }

  body.sidebar-pinned {
    .admin-header, .admin-main {
      padding-left: $sidebar-width;
    }
  }
}

@media (max-width: 992px) {

  .admin-header {
    .sidebar-toggle {
      display: block;
    }

  }
}

.warn-blink {
  font-weight: bold;
  font-size: 20px;
  animation-name: blink;
  animation-duration: 5s;
   animation-iteration-count: 1;
}

@keyframes blink {
    0% {color: pink}
    50% {color: black;}
    100% {color: pink;}
}
