.CircleUserList{
    .list-users {
        list-style-type: none;

        li {
            position: relative;
            text-align: center;
            display: inline-block;
            margin-left: -10px;

            .hint { 
                display: block;
                font-size: 14px;
                color: #BCC4CC;
            }

            &.add-user{
                width: 30px !important;
                height: 30px !important;
            }

            .circle  {
                z-index: 98;
                color: #fff;
                position: relative;
                cursor: pointer;
                width: 34px;
                height: 34px;
                font-size: 20px;
                text-align: center;
                display: inline-block;
                border-radius: 100%;
                background: #1F2532;
                border: 6px solid #fff;
                box-sizing: content-box;
                &.numeric:before {
                    content: attr(data-count);
                }

                &:hover{
                    z-index: 9999;
                }
                &:before {
                    transform: translate(-50%, -50%);
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }
        
                &.mdi-plus-circle {
                    font-size: 18px;
                }


            }
        

        }

        .mdi-check-circle {
            position: absolute;
            top: -11px;
            right: 0;
            z-index: 98;
            color: green;
        }
    }

    
}
.green-mark:after {
    content: '\2713';
    position: absolute;
    top: -15px;
    right: 0px;
    background: #78e08f;
    width: 18px;
    border-radius: 5px;
    border: 1px solid white;
    font-size: 14px;
    height: 25px;
    z-index: 111;
    padding-top: 5px;
    color: white;
}