.grid-table {


    .selectAll{
        .custom-checkbox{
            span{
                color: #f7f7f7;
                &:hover{
                    color:#3e4676;
                }
            }
            &:hover{
                span{
                    color:#3e4676;
                }
            }
        }
        .custom-checkbox input:checked ~ span{
            color:#3e4676;
        }
    }
    .pagination-top {
        display: none;
    }

    .grid-table-wrapper{
        margin-bottom: 0px;
    }

    .results-info{
        padding-right: 20px;
        cursor: default;
    }

    &-wrapper {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 10px;
        box-sizing: border-box;
        height: 75px;
        color: #2e384d;
        -webkit-box-shadow: 0px 0px 5px 1px rgba(232,232,232,1);
        -moz-box-shadow: 0px 0px 5px 1px rgba(232,232,232,1);
        box-shadow: 0px 0px 5px 1px rgba(232,232,232,1);
        background: #fff;

        &:hover {
            -webkit-box-shadow: 0px 0px 0px 4px rgba(64, 143, 248, 1);
            -moz-box-shadow: 0px 0px 0px 4px rgba(64, 143, 248, 1);
            box-shadow: 0px 0px 0px 4px rgba(64, 143, 248, 1);
            .custom-checkbox {
                input ~ .checkmark, i {
                    background-color: #ccc;
                }
            }
        }
    }

    &-checkbox {
        width: 35px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        label{
            &:hover{
                cursor: pointer;
            }
        }
    }

    &-avatar {
        width: 110px;
        height: 75px;
        overflow: hidden;
        position: relative;
        background-size: cover;
        background-position: center;

        span {
            color: #fff;
            position: absolute;
            text-align: center;
            background: transparent;
            z-index: 1;
            height: 20px;
            top: 0;
            left: 0;
            width: 100%;
            padding: 1px 2px;
            font-size: 13px;
        }
    }

    &-time {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 70px;
        height: 100%;

        .jobs-time {
            text-align: center;
            display: block;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.25;
            color: #b0bac9;
            strong {
                display: block;
                color: #2e384d;
                font-weight: 500;
            }
        }
    }

    &-content {
        width: calc(100% - 180px);
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
        position: relative;
        .align-center {
            align-self: center;
        }

        p {
            margin: 0;
        }
    }

    &-rowTitle {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        margin: 0;
    }

    &-row.selected {
        .grid-table-wrapper {
            background-color: #dde3ed;
        }

        &:hover {
            .custom-checkbox {
                input ~ .checkmark {
                    background-color: #408ff8;
                }
            }
        }
    }

    i {
        font-size: 30px;
    }

    &-actions {
        position: absolute;
        top: 0;
        right: 0;
        width: 26px;
        height: 100%;
        color: #2e384d;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &-dropdown {
            position: absolute;
            height: 100%;
            color: #1e2432;
            right: 26px;
            background: #fff;
            z-index: 99;
            display: flex;
            align-items: center;
            transition: all 0.3s ease;
            opacity: 0;
            max-width: 0;
            li {
                display: inline-block;
                padding: 0 15px;
                cursor: pointer;
            }

            i {
                font-size: 24px;
            }
        }
        &:hover {
            color: #408ff8;
        }
        

        i {
            font-size: 30px;
            &:hover{
                color: #2b384f !important;
                cursor: pointer;
            }
        }

        .grid-table-actions-dropdown{
            li:not(first-child){
                display: none;
            }
        }

        &.show {
            .grid-table-actions-dropdown {
                max-width: 300px;
                opacity: 1;
                li:not(first-child){
                    display: block;
                }
            }
        }
    }
}

.FiltersModal {


    .custom-control-label {
        border-radius: 3px !important;
    }

    &-sort {
        padding-top: 70px;
        width: 100%;

        .custom-control-label {
            height: 40px;
            border-radius: 0 !important;
            width: 100% !important;
            font-size: 12px !important;
            text-transform: uppercase !important;
            display: flex !important;
            align-items: center !important;
            justify-content: space-between;

            span {
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #9b9b9b !important;
                text-transform: uppercase;
            }
        }
    }

    &-filters {
        text-align: center;



        &-action {
            text-align: right;
            //padding: 0 30px;
            width: 100%;

            .btn {
                color: #1b1d29;
                font-size: 18px;
                letter-spacing: 1.69px;
                font-weight: 400;
                padding: 10px 0px;
                min-width: 190px;
                text-transform: uppercase;
            }

            .btn-secondary {
                color: white;
                background: #2b384f;
            }

            .btn-default {
                background: none;
                border: solid 1px #1b1d29;
            }
        }

        &-list {
            text-align: left;
            padding-right: 10% !important;
        }

        &-dropdown {
            max-height: 1000px;
            opacity: 1;
            z-index: 900;
            background: #fff;
            padding-bottom: 40px;
        }

        &-item {
            border-bottom: solid 1px #979797;
            padding-bottom: 7px;
            &-title {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 1.5px;
                color: #9b9b9b;
                padding-bottom: 15px;
                i {
                    display: none;
                }
                .pull-right{
                    float: right;
                }
            }

            &-option {
                display: inline-block;

                .custom-checkbox {
                    width: auto;
                    height: auto;
                }

                > span {
                    display: inline-block;
                    padding: 5px 15px;
                    border-radius: 9px;
                    border: solid 1px #1b1d29;
                    text-transform: uppercase;
                    font-size: 11px;
                    color: #fff;
                    background: #1b1d29;
                    min-width: 110px;
                    text-align: center;
                    line-height: 1;
                }

                select, .react-select {
                    min-width: 300px;
                    z-index: 2;
                }
            }
        }

        ul {
            border-radius: 10px;
            padding: 10px;
            width: 100%;
            li {
                margin-bottom: 10px;
            }
        }

        .flatpickr-input {
            display: block;
            width: 100%;
            height: 30px;
            padding: 0.375rem 5px;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.5;
            color: #3e4676;
            background-color: #ffffff;
            background-clip: padding-box;
            border: 1px solid #d3e0e9;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        h6 {
            text-transform: uppercase;
            color: #9b9b9b;
            margin: 0;
            padding: 5px;
            font-size: 14px;
        }
    }

    .custom-control {
        padding: 0 17px 10px 0;

        &-label {
            display: inline-block;
            padding: 5px 15px;
            border-radius: 9px;
            border: solid 1px #1b1d29;
            text-transform: uppercase;
            font-size: 11px;
            color: #1b1d29;
            min-width: 110px;
            text-align: center;
            line-height: 1;
            cursor: pointer;
            &:hover {
                background: #1b1d29;
                color: #fff;
            }
            &:after,
            &:before {
                display: none;
            }
        }

        &-input:checked ~ .custom-control-label {
            background: #1b1d29;
            color: #fff;
        }
    }

    .custom-date {
        display: flex;
        flex-direction: row;
    }

    .circle {
        position: relative;
        cursor: pointer;
        width: 36px;
        height: 36px;
        font-size: 22px;
        text-align: center;
        display: inline-block;
        background: #1b1d29;
        box-sizing: content-box;
        border-radius: 50%;
        &:before {
            transform: translate(-50%, -50%);
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }

    ul {
        margin: 0;
        padding: 0;
    }
    li {
        list-style-type: none;
    }

}

.filters-wrapper {
    position: relative;
    .col {
        position: static !important;
    }
    .SearchBar {
        align-items: center;
        justify-content: center;

        .clear-button{
            background: none;
            border: none;
            width: auto;
            font-size: 17px;
        }
    }
    .FiltersGroup {
        align-items: center;
        justify-content: flex-end;

        .filter-action-title {
            width: 107px;
            display: flex;
            align-items: center;
            border: 1px solid #1b1d29;
            border-radius: 25px;
            font-size: 11px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1b1d29;
            text-transform: uppercase;
            cursor: pointer;
            span {
                display: inline-block;
                padding-left: 10px;
            }
        }
    }
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        list-style-type: none;
    }
    .filters {
        text-align: center;

        &-list {
            text-align: left;
            max-width: calc(100% - 380px);
        }
        &-item {
            border-bottom: solid 1px #979797;
            padding-bottom: 7px;
            &-title {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 1.5px;
                color: #9b9b9b;
                padding-bottom: 15px;
                i {
                    display: none;
                }
            }

            .filter-option {
                display: inline-block;

                > span {
                    display: inline-block;
                    padding: 5px 15px;
                    border-radius: 9px;
                    border: solid 1px #1b1d29;
                    text-transform: uppercase;
                    font-size: 11px;
                    color: #fff;
                    background: #1b1d29;
                    min-width: 110px;
                    text-align: center;
                    line-height: 1;
                }

                select {
                    min-width: 240px;
                }
            }

            .custom-checkbox {
                width: auto;
                height: auto;
            }
        }

        &-action {
            text-align: right;
            padding: 0 30px;
            width: 100%;

            .btn {
                font-size: 18px;
                letter-spacing: 1.69px;
                font-weight: 400;
                padding: 10px 20px;
                min-width: 190px;
                text-transform: uppercase;
            }

            .btn-secondary {
                background: #2b384f;
            }
        }

        .sort {
            width: 340px;
            padding-top: 20px;

            .custom-control-label {
                height: 40px;
                border-radius: 0;
                width: 100%;
                font-size: 12px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    font-size: 16px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #9b9b9b;
                    text-transform: uppercase;
                }
            }
        }
        &.active {
            .filters-dropdown {
                max-height: 1000px;
                opacity: 1;
                z-index: 900;
                background: #fff;
                padding-bottom: 40px;
            }

            .filter-action-title {
                background: #1b1d29;
                color: #fff;
            }
        }
        ul {
            border-radius: 10px;
            padding: 10px;
            width: 100%;
            li {
                margin-bottom: 10px;
            }
        }

        .flatpickr-input {
            display: block;
            width: 100%;
            height: 30px;
            padding: 0.375rem 5px;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.5;
            color: #3e4676;
            background-color: #ffffff;
            background-clip: padding-box;
            border: 1px solid #d3e0e9;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        h6 {
            text-transform: uppercase;
            color: #9b9b9b;
            margin: 0;
            padding: 5px;
            font-size: 14px;
        }
        &-dropdown {
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 5px;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            background: #fff;
            color: #9b9b9b;
            z-index: 2;
            width: 100%;
            max-height: 0;
            opacity: 0;
            transition: all 0.3s ease;
        }
    }
    .react-search-field {
        width: 100%;
        border-radius: 20px;
        position: relative;
        input {
            border-radius: 20px;
            padding-left: 40px !important;
        }

        .react-search-field-button {
            background: none !important;
            border: none !important;
            position: absolute;
            top: 0;
            left: 5px;

            svg {
                width: 16px;
                fill: #4169e1 !important;
            }
        }
    }

    .circle {
        position: relative;
        cursor: pointer;
        width: 36px;
        height: 36px;
        font-size: 22px;
        text-align: center;
        display: inline-block;
        background: #1b1d29;
        border-radius: 50%;
        &:before {
            transform: translate(-50%, -50%);
            color: #fff;
            position: absolute;
            top: 50%;
            left: 39%;
        }
    }

    .custom-control {
        padding: 0 17px 10px 0;

        &-label {
            display: inline-block;
            padding: 5px 15px;
            border-radius: 9px;
            border: solid 1px #1b1d29;
            text-transform: uppercase;
            font-size: 11px;
            color: #1b1d29;
            min-width: 110px;
            text-align: center;
            line-height: 1;
            cursor: pointer;
            &:hover {
                background: #1b1d29;
                color: #fff;
            }
            &:after,
            &:before {
                display: none;
            }
        }

        &-input:checked ~ .custom-control-label {
            background: #1b1d29;
            color: #fff;
        }
    }
}

.ReactTable {
    border: none;
    .rt-thead {
        display: none;
    }

    .custom-checkbox {
        .checkmark, i {
            background: #f7f7f7;
        }
    }

    .rt-tbody .rt-tr-group {
        border: none;
    }

    .rt-th,
    .rt-td {
        white-space: normal;
    }

    .pagination-bottom {
        display: flex;
        justify-content: flex-end;
    }
    .-pagination {
        max-width: 500px;
        width: 100%;
        box-shadow: none;
        border: none;
        .-btn {
            background: none;
            border: none;
        }

        select {
            background: none;
            border: none;
        }
    }
}


.FiltersModal-filters-item-option span{
    background-color: #1b1d29;
    border-radius: 3px;
    padding: 5px;
    color: #fff;
    width: 100%;
    margin-right: 10px;
}

.FiltersModal-filters-item-option {
    .MuiOutlinedInput-input {
        padding: 5px;
    }
    .MuiInputLabel-outlined {
        transform: translate(14px, 6px) scale(1);
    }
}

.WizardStepMultiStagePipeline{
    display: flex;
    flex-direction: column-reverse;
}