//extended from select2 plugin

.select2-container--default .select2-selection__rendered {
  color: $input-color !important;
  padding: $input-padding-y $input-padding-x !important;
  font-size: $font-size-base;
  line-height: $input-line-height !important;

}

.select2-selection {
  border-color: $input-border-color !important;

  height: unset !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: unset;
  position: absolute;
  top: 50%;
  right: 1px;
  width: 20px;
  transform: translateY(-100%);
}

.select2-container--default:focus {
  color: $gray-600;
  background-color: $white;
  outline: 0;
}

.select2-dropdown {
  border: 1px solid $border-color;
  box-shadow: $box-shadow-lg;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $gray-400;
  color: $gray-800;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $gray-200;
  color: $primary;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 0;
  background-color: $gray-200;
  border: none;
  width: 100%;
  outline: 0;
  padding-left: 29px;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 2px .375rem !important;
  font-size: 14px !important;
}

.select2-search--dropdown:before {
  font-family: 'Material Design Icons';
  content: "\F349";
  position: absolute;
  left: 10px;
  top: 6px;
  font-size: 18px;
  color: $gray-600;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: $gray-300;
  border: 1px solid transparent;
  border-radius: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: $black;
  font-weight: 500;
}

//jquery validate
.select2.is-invalid + .select2-container .select2-selection {
  border-color:#f2545b!important;
}

.select2.is-valid + .select2-container .select2-selection {
  border-color:#0c9!important;
}