.ConfirmationButton {
  background: none;
  outline: none;
  padding: 5px;
  color: #ccc;
  border: 2px solid #ccc;

  &:focus,
  &:active{
    outline: none;
  }

  &.active {
    color: green;
    border: 2px solid green;
  }
}