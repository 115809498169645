@import "../../variables";

.GridViewWithRightPanel {
    .searchPill{
        display: flex;
        flex-direction: row;
        
        align-items: center;
        justify-content: space-between;
        min-width: 180px;
        max-width: 180px;
        height: 40px;
        margin-bottom: 10px;
        margin-right: 10px;
        border: 1px solid transparent;
        border-radius: 3px;
        .pillIcon{
            display: flex;
            max-width: 36px;
            min-height: 36px;
            max-height: 36px;
            min-width: 36px;
            i{
                font-size: 26px;
            }
        }
        .pillLabel{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 12px;
            padding: 0px 10px;
            flex: 3;
            overflow: hidden;
            color: #1b1d29;
        }
        .circle{
            width: 36px;
            height: 36px;
            font-size: 24px;
            object-fit: cover !important;
            &::before{
                transform: initial;
                position: initial;
                top: initial;
                left: initial;
            }
        }
        &:hover{
            border: 1px solid #1b1d29;
            cursor: pointer;
            .pillRemove{
                color: #1b1d29;
            }
        }
        .pillRemove{
            font-size: 20px;
            color: #1b1d29;
            padding-right: 5px;
            &:hover{
                cursor: pointer;
            }
        }
    }
    .hidden{
        width: 0% !important;
    }
    overflow-x: hidden;
    margin-right: 0px;
    min-height: calc(100vh - 65px);

    > .row {
        margin: 0;
    }
    .BackButton {
        margin: 0px;
    }
    .cstm-switch {
        margin-top: 10px;
    }
    .DataGrid {
        padding-top: 15px;
        .filters-wrapper {
            margin-bottom: 15px;

            @include mobile() {
                margin-bottom: 0;
            }
        }
    }
    @keyframes width {
        from {right: -100%}
        to {right: 0px}
    }
    .RightPanelWrapper {
        position: relative;
        border: 0;
        height: 100%;
        overflow-y: scroll;
    }
    .RightPanel {
        animation-name: width;
        animation-duration: 0.5s;
        background: #fff;
        padding: 0;
        width: 100%;
        text-align: left;
        height: calc(100% - 65px);
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 65px;
        .close{
            color: #fff !important;
            background: rgba(204, 204, 204, 0.5);
            position: absolute;
            font-size: 35px;
            width: 40px;
            height: 40px;
            align-items: center;
            display: flex;
            justify-content: center;
            left: 30px;
            top: 20px;
            z-index: 9999;
            opacity: 1;
        }
        .RightPanelContent {
            height: 100%;
            width: 100%;
            text-align: left;
            .UserEditor {
                padding: 0;
            }
        }

        .EditorView {
            text-align: left;
            font-size: 16px;

            .avatar-img {
                margin-bottom: 10px;
                width: 140px;
                height: 140px;
            }
            p {
                margin-bottom: 2px;
            }
            .col {
                margin-bottom: 10px;
            }
            .user-name {
                font-size: 22px;
            }

            .editor-title {
                color: #b0bac9;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 5px;
                position: relative;
                letter-spacing: 1.13px;
                i {
                    font-size: 24px;
                    position: absolute;
                    right: 0;
                    top: -8px;
                }
            }

            .ico-info {
                position: absolute;
                top: 0;
                left: -5px;
                color: #bcc4cc;
            }
            .user-hint {
                color: #bcc4cc;
                font-size: 14px;
            }

            .circle {
                background: #2e384d;
                box-sizing: content-box;
                border: 6px solid #fff;
                border-radius: 50%;
                width: 34px;
                height: 34px;
                text-align: center;
                line-height: 28px;
                color: #fff;
                left: 0;
                top: 0;
                cursor: pointer;
            }

            .share {
                position: absolute;
                top: auto;
                bottom: 10px;
                right: 10px;
                left: auto;
            }

            .list-timeline {
                margin: 0;
                padding: 5px 0 10px 5px;
                li {
                    list-style-type: none;
                    position: relative;
                    padding: 0 0 5px 15px;
                    margin: 0;
                }
            }

            &-head {
                margin-bottom: 20px;
            }

            &-content {
                padding: 0 30px;
                margin-top: -20px;
            }

            &-title {
                margin: 20px 0;
                font-size: 28px;
                font-weight: 300;
                line-height: 1.14;
                color: #2e384d;
                text-align: center;
                > span {
                    padding-bottom: 5px;
                    border-bottom: 1px solid #e4e8f0;
                }

                p {
                    cursor: pointer;
                }
            }

            &-hint {
                display: block;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                line-height: 1.13;
                color: #b0b8cc;
                cursor: pointer;
            }

            &-hint-small {
                display: block;
                text-align: center;
                font-size: 12px;
                font-weight: 200;
                line-height: 2;
                color: #b0b8cc;
                cursor: pointer;
            }

            &-actions {
                display: flex;
                width: 100%;
                flex-direction: row;
                button {
                    display: flex;
                    text-align: center;
                    font-size: 12px;
                    padding: 10px;
                    height: 52px;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    i {
                        margin: 0;
                        margin-right: 5px;
                    }

                    span {
                        display: block;
                    }
                }
            }
        }

        .actions {
            padding: 20px 0 0 20px;
            text-align: left;
            .btn {
                min-width: 150px;
                border-radius: 20px;
                margin-bottom: 20px;
            }
        }

        .share-container {
            opacity: 0;
            height: 0;
        }
    }

    @include mobile() {
        .RightPanel {
            position: fixed;
            top: 0;
            overflow: auto;
            height: 100%;
            padding-bottom: 0;
            z-index: 999;

            i.close {
                position: absolute;
                font-size: 36px;
                line-height: 1;
                top: 5px;
                left: 10px;
                z-index: 10;
                opacity: 1;
            }

            .EditorView {
                &-content {
                    padding: 0;
                    margin-top: 10px;
                }
            }
        }

        .RightPanelWrapper {
            overflow-x: hidden;
        }

        .DataGrid {
            padding-top: 10px;
        }

        .grid-table {
            &-content {
                width: calc(100% - 70px);
                text-align: center;
                padding: 5px 0;

                .align-center {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    border-left: 1px solid #e4e8f0;
                }

                .row {
                    margin: 0;
                    width: 100%;
                }
            }

            &-wrapper {
                height: 71px;
                margin: 0;
                border: none;
                border-bottom: 1px solid #e4e8f0;
                box-shadow: none;
            }

            &-checkbox {
                display: none;
            }

            &-hint {
                margin: 0;
                font-size: 12px;
                line-height: 1.83;
                color: #b0bac9;
            }

            &-avatar {
                width: 70px;
                height: 70px;
            }
        }
    }
}
