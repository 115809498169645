.JobEditor {
  &-remove_attendance {
    cursor: pointer;
    color: red;
  }
  .cstm-switch-description {
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 5px;
    font-weight: 500;
  }
  .cstm-switch {
    height: 50px;
  }
  .jobName {
    font-size: 22px;
  }

  .MultiUserView {
    text-align: center;
    padding: 65px 0 50px;
    h2 {
      font-size: 28px;
    }
  }

  .EditorView {
    &-start_date {
      margin-bottom: 5px;
      input {
        background-color: white;
        border-radius: 15px;
        text-align: center;
      }
    }
    &-time {
      margin-bottom: 5px;

      input.flatpickr-input {
        background-color: inherit;
        color: #fff;
        font-size: 14px;
        border: none;
      }
    }
    &-avatar {
      position: relative;
      height: 250px;
      margin-bottom: 40px;
      background-position: center;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-size: cover;
      background-repeat: no-repeat;
      .ImagePreview {
        position: absolute;
        top: 5px;
        right: 0px;
        .btn-success {
          border: none;
          background: none;
          margin: 0;

          &:not(:disabled):not(.disabled).active,
          &:not(:disabled):not(.disabled):active,
          &:focus,
          &:hover {
            border: none;
            background: none;
            box-shadow: none;
          }
        }
        .circle {
          color: #fff;
          position: relative;
          cursor: pointer;
          width: 30px;
          height: 30px;
          font-size: 20px;
          text-align: center;
          border-radius: 100%;
          background: #2e384d;
          display: inline-block;
          &:before {
            transform: translate(-50%, -50%);
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
          }
        }
      }
    }

    &-content {
      position: relative;
    }

    &-head {
      .react-select {
        width: 200px;
      }
    }

    .question-buttons {
      margin-top: 20px;
      margin-bottom: 20px;
      .btn {
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        padding: 10px 20px;
      }
    }

    .CircleUserList {
      position: absolute;
      left: 30px;
      top: 236px;
      z-index: 0;
    }

    .ProjectFields {
      .CircleUserList {
        position: unset;
        z-index: 0;
      }
    }

    .share-container {
      position: absolute;
      z-index: -1;
    }

    .project-title {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #2e384d;
    }

    .hint-id {
      font-size: 15px;
      line-height: 1.47;
      color: #b0bac9;
      width: 100%;
      text-align: right;
    }
  }
}

.editor {
  &-attendance {
    font-size: 12px;

    &.in-blue {
      color: #78e08f;
    }
  }
}

.JobPanelQuestion {
  min-height: 134px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 13px 30px;
  margin: 2.5px -30px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);

  .signature {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &.issued {
    border-top: 5px solid #e837c9;
    background-color: rgba(0, 0, 0, 0.08);
  }
  &-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  &-icon {
    //padding-top: 2px;
    .material-icons {
      font-size: 22px;
      color: #b0bac9;
    }
  }
  &-title {
    padding-left: 13px;
    span {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #b0bac9;
    }
  }
  &-answer {
    width: 100%;
    h6 {
      font-family: "Rubik";
      font-size: 18px;
      font-weight: 300;
      line-height: 20px;
      color: #2e384d;
      letter-spacing: -1px;
    }
    .ListComponent {
      padding: 0px;
      .SidePanelListItem {
        width: 100%;
        padding: 5px;
        .dialog-tooltip {
          width: 100%;
          text-align: center
        } 
      }
      .show-more {
        margin-left: 10px;
        text-decoration: underline;
      }
    }
    .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
      height: auto !important;
    }
  }
  &-files {
    i {
      font-size: 30px;
    }
    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
  }
  &-comments {
    height: 30px;
    line-height: 30px;
    i {
      font-size: 30px;
      color: #b0bac9;
    }
  }
  &-footer {
    flex: 1;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    .CommentAndFiles {
      display: flex;
    }
    .CurrentUserInfo {
      color: #b0bac9;
      .history{
        font-size: 0.75rem;
        margin-left: 5px
      }
    }
  }
  &-history {
    width: 100%;
    margin-top: 10px;
    border-top: 2px solid #b0bac9;
    .ListComponent {
      padding: 0px;
    }
  }
  
}

.Jobs {
  .GridViewWithRightPanel {
    .RightPanelWrapper {
      .actions {
        padding: 20px 0 0 0;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .JobPanelQuestion {
    margin: 2.5px 0px;
  }
  .editor-title,
  .project-title,
  .EditorView-hint,
  .question-buttons,
  .hint-id,
  .list-activity {
    padding: 0 22px;
  }
  .view-all-text {
    padding: 0px;
  }
}

.answer-history-block {
  position: relative;
}

.answer-history-data {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  /* position: relative;
  top: 0;
  left: -10px;
  float: left; */
  //background-color: #f5f6fb;
  //border-radius: 5px;
  //box-shadow: 0px 0px 5px black;
  /* z-index: 1;
  width: max-content;
  z-index: 99999; */

  /* &.show {
    display: block;
  } */
  .user-data {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .UserAnswers {
    border-bottom: 1px solid white;
    justify-content: center;
    align-items: center;
    p {
      color: #999;
      padding: 0.1rem !important;
      text-overflow: ellipsis;
      max-width: 116px;
      overflow: hidden;
    }

    &-item {
      text-align: right;
    }
    span.nav-profile-avatar {
      img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
      }
      i {
        font-size: 30px;
      }
    }
    &-user {
      color: #b0bac9;
      font-size: 16px;
      .history-date{
        font-size: 0.75rem;
        margin-left: 5px;
      }
    }

    &-value {
      color: #2e384d;
      font-size: 14px;
    }
  }
}
.tab-icon {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 100%;
  background-size: cover;
  background-color: #2e384d;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.answer-all-wrapper {
  display: flex;
  justify-content: center;
}

.btn-answer-all {
  padding: 10px 25px !important;
  margin: 10px 0px;
}