@import "../../../variables";

.header {
    height: 65px !important;
    &.color-primary{
        background: #fff !important;
    }

    .search{
        display: none;
    }
    &.admin-header {
        background: #1f2431;
        color: #fff;
    }
    &-content {
        padding: 0 20px;
        padding-right: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .new {
        font-size: 14px;
        line-height: 1;
        padding: 8px 20px 5px;
    }

    h1 {
        font-size: 26px;
        margin: 0;
        margin-left: 25px;
    }

    .nav {
        display: inline-block;
        vertical-align: middle;
        margin: 0 50px;
    }

    .nav-profile {
        display: inline-block;
        position: relative;

        li {
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
            list-style-type: none;
            margin-right: 24px;
            cursor: pointer;

            .circle-link {
                position: relative;
                display: inline-block;
                width: 28px;
                height: 28px;
                background: #fff;
                border-radius: 50%;
                color: #9B9B9B;
                vertical-align: middle;
                &-badge {
                    background-color: #fa3e3e;
                    border-radius: 2px;
                    color: white;
                    
                    padding: 1px 3px;
                    font-size: 10px;
                    
                    position: absolute; /* Position the badge within the relatively positioned button */
                    top: 0;
                    right: 0;
                }

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 20px;
                }
            }
        }

        .nav-utils {
            margin: 10px 0 0;
            left: 50%;
            top: 100%;
            transform: translateX(-50%);

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #fff transparent;
                position: absolute;
                top: -10px;
                left: 50%;
                margin-left: -10px;
            }

            span {
                display: block;
                padding: 5px 10px;
                cursor: pointer;
            }

            a {
                display: block;
                padding: 5px 10px;
                cursor: pointer;
                margin-left: 10px;
            }
        }

        &-user {
            font-size: 16px;
            font-weight: 400;
            span{
                display: inline-block;
                vertical-align: middle;
            }
            span:not(.nav-profile-avatar) {
                margin-left: 10px;
            }
        }

        &-avatar {
            margin-right: 5px;
            img{
                width: 40px !important;
                height: 40px !important;
                border-radius: 20% !important;
            }
             i{
                 font-size: 26px !important; 
             }
        }
    }

    .new {
        text-transform: uppercase;
    }

    .headers-buttons {
        button {
            margin-right: 10px;
            border: 1px solid #fff;
            color: #fff;
        }
    }
}
.new-type {
    padding: 20px;

    .option-box-grid {
        display: block;
        label {
            width: 100%;
        }
    }
}
@include mobile() {
    .header {
        h1 {
            font-size: 20px;
            letter-spacing: 1.88px;
            color: #9b9b9b;
            text-transform: uppercase;
            margin-left: 0px;
        }

        &-content {
            padding: 0 20px;
            justify-content: space-between;
        }

        &.admin-header {
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
            background-color: #fff;
            color: #9b9b9b;
        }
    }
}

@media (max-width: 992px) {
    .header {
        .search {
            font-size: 26px;
            display: inline-block;
        }
    }
}
