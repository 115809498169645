.ReportsBuilder{
    height: calc(100% - 73px);
    .ReportButtonWithIcon{
      height: 100%;
      transition: all 0.5s ease;
      &.disabled{
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:not(.disabled):hover{
          background: rgba(46, 91, 255, 0.1);
          cursor: pointer;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .icon-line-chart{
        font-size: 3.5rem;
      }
    }
    .bottom-offset{
        height: 50px;
    }
    .react-tags__search{
        .form-control{
            width: 100% !important;
        }
    }
    .form-control{
        margin-bottom: 10px;
    }

    .react-tags__selected{
        border: 1px solid #000;
        min-height: 150px;
        margin-bottom: 10px;
        padding: 10px;
    }

    .reportTag{
        background-color: #fff;
        margin-left: 2px;
        margin-right: 2px;
        padding: 5px;
        width: auto;
        margin: 2px;
        display: inline-block;
        cursor: pointer;
        .tagDeleteButton{
            font-weight: light;
            padding-left: 5px;
            padding-right: 5px;
            &:hover{
                cursor: pointer;
            }
        }
        &.jobs{
            color: #fff;
            background-color: #FFE28A;
        }
        &.projects{
            color: #fff;
            background-color: blue;
        }
        &.users{
            color: #fff;
            background-color: pink;
        }
        &.job_type{
            color: #fff;
            background-color: green;
        }
    }
  
  .react-tags__suggestions ul {
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    z-index: 9999;
    width: calc(100% - 30px);
  }
  
  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }
  
  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  
  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }
  
  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }
  
  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }
  .react-tags{
    margin-bottom: 75px;
  }
}