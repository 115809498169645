.ConfirmModal{
    position: absolute;
    .ConfirmModal-body{
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid #000;
        padding: 20px;
        .btn{
            margin-right: 10px;
        }
    }
}