//Buttons

.btn {
  box-shadow: $btn-box-shadow;
}

.btn-ghost {
  opacity: 0.7;
  transition: all ease 0.2s;
  box-shadow: none;
  &.active,
  &:hover,
  &:active,
  &:focus {
    opacity: 1;
    background-color: lighten($black-translucent, 90);
    box-shadow: none;
  }
}
.btn-white {
  box-shadow: none;
  border: 1px solid $border-color;
  background-color: #fff;
  color: $body-color;
  &:hover,
  &:active,
  &:focus {
    outline: 0;
    box-shadow: none;
    color: $black;
    background-color: $gray-100;

  }
}

.btn-white-translucent {
  background-color: $white-translucent;
  color: #fff;
  &:hover,
  &:active,
  &:focus{
    background-color: darken($white-translucent , 50);
    color: #fff;
  }
}



.btn-black-translucent {
  color: #fff;
  background-color: $black-translucent;
  &:hover,
  &:active,
  &:focus{
    background-color: darken($black-translucent , 50);
    color: #fff;
  }
}


.btn-rounded-circle {
  width: calc(1em * #{$btn-line-height} + #{$input-btn-padding-y * 2 } + #{$btn-border-width} * 2);
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}

.btn-rounded-circle.btn-lg {
  width: calc(1em * #{$btn-line-height-lg} + #{$input-btn-padding-y-lg * 2 } + #{$btn-border-width} * 2);
}

.btn-rounded-circle.btn-sm {
  width: calc(1em * #{$btn-line-height-sm} + #{$input-btn-padding-y-sm * 2 } + #{$btn-border-width} * 2);
}

.btn-rounded{
  border-radius: 100px !important;
}

.btn-rounded-left{
  border-radius: 100px 0 0 100px !important;
}

.btn-rounded-right{
  border-radius: 0 100px 100px 0 !important;
}