//helper

//full page with no padding like login,404 etc
body.jumbo-page {
  .admin-main {
    padding: 0;
  }
}

img {
  max-width: 100%;
}

.opacity-1 {
  opacity: 1;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-50 {
  opacity: 0.50;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-0 {
  opacity: 0;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}
