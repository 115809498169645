@import "./variables";
@import "./theme//bootstrap/scss/bootstrap";
@import "./theme/atmos";
@import "./assets/fonts/materialdesignicons.min.css";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap");
@import '~video-react/styles/scss/video-react';

html {
	body {
		background: #fff;

		* {
			font-family: Rubik, sans-serif;
		}
	}
}

body ul,
menu,
dir {
	padding: 0;
	margin: 0;
}

.admin-main {
	padding: 0px;
	padding-top: 65px;
	padding-left: 70px;
	padding-right: 0;
	align-items: center;
	justify-content: center;
	flex: 1;
	display: flex;
}

.not-implemented {
	opacity: 0.5;
}

.not-implemented-text {
	font-size: 50px;
	position: absolute;
	z-index: 9999;
	color: red;
	width: 500px;
	text-align: center;
	height: 50px;
	top: 25%;
	left: 50%;
	margin-left: -250px;
	margin-top: -25px;
	transform: rotate(-45deg);
	/* Safari */
	-webkit-transform: rotate(-45deg);

	/* Firefox */
	-moz-transform: rotate(-45deg);

	/* IE */
	-ms-transform: rotate(-45deg);

	/* Opera */
	-o-transform: rotate(-45deg);
}

.rotate180 {
	-webkit-transform: rotate($rotate180);
	-moz-transform: rotate($rotate180);
	-o-transform: rotate($rotate180);
	-ms-transform: rotate($rotate180);
	transform: rotate($rotate180);
}

.pointer {
	cursor: pointer;
}

.pointer:hover {
	cursor: pointer;
}

.ReactTable {
	.questions-zone {
		padding: 0 10px;
	}

	.jobs-questions {
		height: 100%;
		width: 100%;

		.carousel {
			height: 100%;
		}

		>li {
			list-style-type: none;
			display: inline-block;
			width: 145px;
			border-right: 1px solid #2b384f;
			padding: 5px 10px;
			height: 100%;
		}

		.question-title {
			font-size: 12px;
			margin: 0;
			padding: 0;
			font-weight: 400;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.question-issue {
			width: 100%;
			min-height: 3px;

			&.issued {
				background-color: #e837c9;
			}
		}

		.question-answer {
			margin: 0;
			padding: 0;
			font-size: 12px;
			overflow: hidden;
			text-overflow: ellipsis;
			min-height: 25px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			line-clamp: 2;
			box-orient: vertical;
		}
	}

	i.no-value {
		color: #b0bac9;
	}
}

.ReactModal__Overlay {
	z-index: 9998;
}

.Modal {
	height: 100%;

	&-header {
		padding: 20px;
		background: #2b384f;
		color: #fff;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;

		h5 {
			text-transform: uppercase;
			padding: 0;
			margin: 0;
			color: #fff;
			font-family: "Roboto";
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 1.69px;
			text-align: center;
			display: inline-block;
			width: 100%;
			padding-top: 8px;
		}
	}

	&-content {
		height: calc(100% - 73px);
		padding: 10px 20px;
	}
}

.form-group {
	&.float-label {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		label {
			display: inline-block;
			margin: 0;
			min-width: 180px;
			font-size: 14px;
			font-weight: 500;
			line-height: normal;
			letter-spacing: 1px;
			color: #2e384d;
			text-transform: uppercase;
		}

		input {
			background: #f5f6fb;
			height: 45px;
			border: none;
			border-radius: 0;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #3d5170;
		}
	}
}

.float-switch {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-direction: row-reverse;

	.cstm-switch-description {
		font-size: 14px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #b0b8cc;
		margin: 0;
	}

	.cstm-switch-input:checked~.cstm-switch-description {
		color: #2e384d;
	}
}

.float-switch+.float-switch {
	margin-top: 30px;
}

.custom-radio {
	label.container {
		display: block;
		position: relative;
		cursor: pointer;
		padding-left: 20px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.13;
		letter-spacing: normal;
		color: #b0bac9;
		margin: 0;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}

		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 16px;
			width: 16px;
			background-color: #fff;
			border-radius: 50%;
			border: 1px solid #e4e8f0;
			display: block;

			&.checked {
				background-color: #e4e8f0;
			}
		}

		.checkmark:after {
			content: " ";
			position: absolute;
			display: none;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: #2e384d;
		}

		&:hover input~.checkmark {
			background-color: #e4e8f0;
		}

		input:checked~.checkmark {
			border-color: #2e384d;
		}

		input:checked~.checkmark:after {
			display: block;
		}

		.active~.checkmark:after {
			display: block;
		}
	}
}

.react-select {
	width: 100%;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 1.03px;
	color: #9b9b9b;
	text-transform: uppercase;

	&__indicator-separator {
		display: none;
	}

	&__indicator {
		svg {
			fill: #2e5bff;
		}
	}

	&__input {
		position: absolute;
		top: 0;
	}

	& &__menu-list,
	& &__menu {
		margin: 0;
		padding: 0;
		border-radius: 0;
	}

	& &__option {
		padding: 16px 12px;
		z-index: 9999;
	}

	& &__option--is-focused {
		background-color: #d3d8e4;
		z-index: 9999;
	}
}

.fileList {
	.file {
		list-style-type: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		min-width: 350px;

		&-image {
			margin-right: 10px;

			i {
				font-size: 36px;
			}

			img {
				max-width: 80px;
			}
		}

		&-content {
			padding: 20px 10px;

			h6 {
				margin: 0;
			}
		}

		&-actions {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			z-index: 2;

			i {
				font-size: 30px;
			}

			&-list {
				position: absolute;
				background: #fff;
				list-style-type: none;
				top: 100%;
				right: 0;
				visibility: hidden;
				max-height: 0;
				transition: all 0.3s ease;

				li {
					display: block;
					padding: 5px;
					border-bottom: 1px solid #ccc;
				}

				&-show {
					visibility: visible;
					max-height: 200px;
				}
			}
		}
	}
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.m-b-10 {
	margin-bottom: 10px;
}

.strong {
	font-weight: bold;
}

.NotificationModal {
	.ReactModal__Content.ReactModal__Content--after-open {
		top: 50% !important;
		left: 85% !important;
		right: auto !important;
		bottom: auto !important;
		margin-right: -50% !important;
		transform: translate(-50%, -50%) !important;

		.alert {
			margin: 20px auto 0;
		}
	}
}

.ReactModal__Content.ReactModal__Content--after-open {
	top: 50% !important;
	left: 50% !important;
	right: auto !important;
	bottom: auto !important;
	margin-right: -50% !important;
	position: absolute;
	transform: translate(-50%, -50%) !important;

	.alert {
		margin: 20px auto 0;
	}
}

.files-count {
	font-size: 13px;
	font-weight: 500;
	line-height: 1;
	letter-spacing: normal;
	color: #2e384d;
	display: flex;
	align-items: center;

	i {
		font-size: 24px;
		margin-right: 5px;
	}
}

.Modal-materials,
.Modal-SelectUser {
	position: relative;
	padding-bottom: 60px;
	height: 100% !important;
	position: relative;

	.title {
		margin-bottom: 20px;
	}
}

.hidden {
	display: none;
}

.error-message {
	color: #dc3545;
}

.strong {
	font-weight: bold;
}

.tagging {
	min-width: 300px;
	min-height: 200px;
}

.center {
	text-align: center;
}

.list-users {
	padding: 0;
	margin: 0;
	display: inline-flex;
	flex-direction: row;

	li {
		position: relative;
		display: inline-block;
		font-size: 30px;
		padding-right: 2px;
		line-height: 1;

		&:hover {
			.user-content {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.user-content {
		font-size: 14px;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		visibility: hidden;
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.2);
		display: inline-block;
		padding: 0px 10px;
		border-radius: 20px;
		transition: all 0.3s ease;
		z-index: 1;
	}
}

.list-activity {
	margin-top: 10px;

	li {
		list-style-type: none;
		font-size: 14px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.14;
		letter-spacing: normal;
		color: #2e384d;
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid rgba(160, 178, 184, 0.2);

		span {
			width: 81px;
			height: 14px;
			font-family: Rubik;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #313e47;
		}
	}
}

.list-projects {
	li {
		list-style-type: none;
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #e4e8f0;
		padding: 5px 0;
		line-height: 1.88;
		font-size: 16px;

		img {
			max-width: 50px;
			margin-right: 10px;
		}

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.questionSection {
	padding: 0 0 0 40px;
	margin-top: 20px;
	position: relative;

	.question-title {
		font-size: 16px;
		letter-spacing: 1.13px;
		padding: 0;
		color: #2e384d;

		i {
			position: absolute;
			top: 0;
			left: 0;
			font-size: 26px;
			line-height: 1;
		}
	}

	.question-answer {
		font-size: 14px;
		font-weight: 500;
		line-height: 1.57;
		color: #2e384d;

		.question-comments {
			font-size: 12px;
			font-weight: 400;
			line-height: 1.83;
			letter-spacing: normal;
			color: #8e949f;
		}

		.question-files {
			font-size: 12px;
			font-weight: 400;
			line-height: 1.83;
			letter-spacing: normal;
			color: #2e384d;
		}
	}
}

.li-link {
	list-style-type: none;

	li {
		list-style-type: none;
		color: rgb(0, 110, 255);
	}

	li:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

#toast-container {
	text-align: center;
	margin-top: 80px;
}

.toast-success {
	background-color: green;
}

.toast-error {
	background-color: red;
}

.toast-info {
	background-color: blue;
}

.toast-warning {
	background-color: yellow;
}

.red {
	color: red;
}

.bold {
	font-weight: bold;
}

.btn-full {
	width: 100%;
	padding: 25px;
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	border: none;
	color: #fff;
	background: #2e384d;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: center;

	i {
		font-size: 30px;
		line-height: 1;
		margin-right: 10px;
	}
}

.btn-red {
	background: #f05d63;
}

.btn-lightgray {
	background: #aeb8ce;
}

.filter-sort {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: solid 1px #1b1d29;
	color: #1b1d29;
	min-width: 110px;
	cursor: pointer;
	position: relative;
	margin-bottom: 10px;

	&-title {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #9b9b9b;
		text-transform: uppercase;
		position: absolute;
		top: 13px;
		left: 15px;
		z-index: 1;
		pointer-events: none;
	}

	.react-select {
		border: none !important;
		width: 100%;
		text-align: right;
		border-radius: 0;

		&:focus {
			outline: none;
		}

		&__control {
			border: none !important;
			outline: none !important;
			text-align: right;
			padding-left: 60px;
			border-radius: 0;
			min-height: 42px;
		}

		&__indicators {
			display: none;
		}

		&__single-value,
		&__placeholder {
			width: 90%;
		}
	}
}

.custom-checkbox {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
	position: relative;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 0;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #f7f7f7;
		color: #fff;
		border-radius: 50%;

		&:after {
			content: "";
			position: absolute;
		}
	}

	.checkmark:after {
		left: 8px;
		top: 5px;
		width: 5px;
		height: 9px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	&:hover input~.checkmark {
		background-color: #ccc;
	}

	input:checked~.checkmark {
		background-color: #408ff8;
	}

	// input:checked ~ .checkmark:after {
	//     display: block;
	// }
}

.hamburger {
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
	background-color: #9b9b9b;
}

.hamburger-box {
	width: 30px;
	height: 24px;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 30px;
	height: 2px;
	background-color: #9b9b9b;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
	content: "";
	display: block;
}

.hamburger-inner::before {
	top: -7px;
}

.hamburger-inner::after {
	bottom: -10px;
}

.hamburger--collapse .hamburger-inner {
	top: auto;
	bottom: 0;
	transition-duration: 0.13s;
	transition-delay: 0.13s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
	top: -14px;
	transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
	transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
	transform: translate3d(0, -10px, 0) rotate(-45deg);
	transition-delay: 0.22s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
	top: 0;
	opacity: 0;
	transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
	top: 0;
	transform: rotate(-90deg);
	transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.editor :global(.public-DraftEditor-content) {
	min-height: 140px;
}

.draftJsEmojiPlugin__emojiSelect__34S1B {
	padding-left: 10px;
}

.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
	z-index: 10000 !important;
}

@include mobile() {
	body {
		background: #fff;
	}

	.admin-main {
		padding: 65px 0 20px;
	}

	.hamburger {
		display: inline-block;
	}

	.ReactTable {

		.rt-th,
		.rt-td {
			padding: 0 0 5px;
		}
	}

	.form-group {
		&.float-label {
			margin-bottom: 25px;
			display: block;

			label {
				display: block;
				width: 100%;
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 1px;
				color: #2e384d;
			}

			input {
				background: #f5f6fb;
				height: 45px;
				border: none;
				border-radius: 0;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #3d5170;
			}
		}
	}

	.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
		width: 100%;
		left: 0;
	}

	.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
		width: 100% !important;
	}

	.filter-sort {
		width: 95%
	}
}

@media (max-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
}

.EditorView-time {
	width: 100px;
	overflow: hidden;
	height: 100px;
	background-color: #2e384d;
	margin: 0 auto;
	border-radius: 100%;
	color: #fff;
	font-size: 26px;
	line-height: 26px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-size: cover;

	strong {
		display: block;
		font-size: 25px;
		line-height: 0.88;
		font-weight: 400;
	}

	span {
		display: block;
		font-size: 16px;
		line-height: 1.25;
	}
}

h5,
.heading-styled {
	font-size: 16px;
	font-family: "Roboto" !important;
	font-weight: 500;
	line-height: 22px;
	color: #2e384d;
}

.mdi-map-marker-outline {
	font-size: 22px;
}

.btn-full {
	margin-bottom: 0px;

	i {
		font-size: 15px;
		margin-bottom: 6px;
	}

	span {
		font-weight: 500;
		font-size: 11px;
		letter-spacing: 1px;
	}

	&.btn-gray {
		background-color: #b0b8cc !important;
	}
}

.btn-square {
	border-radius: 0px;
	height: 50px;
}

.font-15 {
	font-size: 15px;
}

.NoPadding {
	padding: 0px;
}

@media only screen and (max-width: 992px) {
	.btn-my-location {
		font-size: 8px;
		height: 38px;
	}

	.ReactModal__Overlay {
		z-index: 9997;
	}

	.ReactModal__Content.ReactModal__Content--after-open {
		width: 100% !important;
		height: 100% !important;
		top: 0px !important;
		left: 0px !important;
		background-color: #fff;
		transform: initial !important;

		.actions {
			position: fixed;
			bottom: 0px;
			left: 0px;
			align-items: center;
			flex-direction: row;
			justify-content: center;
			display: flex;
			padding: 10px;

			button {
				flex: 1;
			}
		}

		.Autoselect-user-avatar {
			i {
				width: 30px;
				height: 30px;
			}
		}

		.Autoselect .Autoselect-item,
		.Autoselect .Autoselect-user {
			padding: 0px 0px 3px 40px;
		}
	}
}

.NotificationPanelWrapper {
	padding: 0;
	height: calc(100% - 65px);
	overflow-y: scroll;
	position: fixed;
	top: 65px;
	right: 0;
}

.FiltersModal {
	.select-search-box::before {
		border-style: solid;
		border-width: 0.25em 0.25em 0 0;
		content: "";
		display: inline-block;
		height: 1em;
		position: absolute;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		vertical-align: top;
		width: 1em;
		top: 1em;
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
		right: 1em;
	}
}

.select-search-box {
	width: 300px;
	position: relative;
	margin-bottom: 20px;
	background: #fff;
	border-radius: 4px;
}

.select-search-box--multiple {
	border-radius: 4px;
	box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
	overflow: hidden;
}

.select-search-box::before {
	border-style: solid;
	border-width: 0.25em 0.25em 0 0;
	content: "";
	display: inline-block;
	height: 0.45em;
	position: absolute;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 0.45em;
	top: 0.5em;
	transform: rotate(135deg);
	right: 0.5em;
}

.select-search-box__icon--disabled::after {
	content: none !important;
}

.select-search-box--input::after {
	display: none !important;
}

.select-search-box__out {
	display: none;
}

.select-search-box__search {
	display: block;
	width: 100%;
	height: 50px;
	border: none;
	background: none;
	outline: none;
	font-size: 16px;
	padding: 0 20px;
	color: #222f3e;
	-webkit-appearance: none;
	box-sizing: border-box;
	position: relative;
	z-index: 2;
	cursor: pointer;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	line-height: 50px;
	box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

input.select-search-box__search {
	line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
	box-shadow: none;
}

.select-search-box--input .select-search-box__search {
	cursor: text;
}

.select-search-box__search:focus {
	cursor: text;
}

.select-search-box__search--placeholder {
	font-style: italic;
	font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
	color: #ccc;
	font-style: italic;
	font-weight: normal;
}

.select-search-box input::-moz-placeholder {
	color: #ccc;
	font-style: italic;
	font-weight: normal;
}

.select-search-box input:-moz-placeholder {
	color: #ccc;
	font-style: italic;
	font-weight: normal;
}

.select-search-box input:-ms-input-placeholder {
	color: #ccc;
	font-style: italic;
	font-weight: normal;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

.select-search-box__select {
	display: none;
	position: absolute;
	top: 60px;
	left: 0;
	right: 0;
	background: #fff;
	border-radius: 4px;
	overflow: auto;
	box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
	z-index: 100;
	min-height: 49px;
}

.select-search-box--multiple .select-search-box__select {
	display: block;
	position: static;
	border-top: 1px solid #eee;
	border-radius: 0;
	box-shadow: none;
}

.select-search-box__select--display {
	display: block;
}

.select-search-box__option {
	font-size: 16px;
	font-weight: 400;
	color: #616b74;
	padding: 15px 20px;
	border-top: 1px solid #eee;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
}

.select-search-box__option:first-child {
	border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
	background: #f4f7fa;
}

.select-search-box__option--selected {
	background: #54a0ff;
	color: #fff;
	border-top-color: #2184ff;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
	background: #2184ff;
	color: #fff;
	border-top-color: #2184ff;
}

.select-search-box__group {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #eee;
	position: relative;
}

.select-search-box__group-header {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	padding: 0 10px;
	color: rgba(0, 0, 0, 0.5);
	font-size: 12px;
}

.JobModal {
	.btn {
		margin-top: 50px;
	}
}

.ProjectModal {
	.btn {
		margin-top: 120px;
	}
}

.Modal-actions {
	.btn-primary {
		border-radius: 0px !important;
		text-transform: uppercase;
		font-family: "Roboto";
		font-weight: 300;
		letter-spacing: 1.69px;
	}
}

.Modal-actions,
.actions {
	text-align: right;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
}

@media (min-width: 997px) {
	.Modal-SelectUser {
		.Modal-content {
			max-height: 470px;
			overflow-y: scroll;
		}
	}
}

.trainee {
	border: 2px solid #eb4d4b;
	box-sizing: content-box;
}

.circle {
	object-fit: contain !important;
}

.Autoselect-user-content {
	height: 100%;
}

.Autoselect-user-avatar {
	width: 50px;
	height: 45px;
	border-radius: 50%;
	padding-left: 10px;
}

.blue-frame {
	border: 6px solid #269ce0;
	padding: 14px 0px;
}

.Modal-header {
	background: #fff;
	box-shadow: 1px 1px 1px #269ce0;
	padding: 10px 20px;
	font-family: 'Cataraman';
	height: 50px;
	box-shadow: 1px 1px 1px #d7d7d7;
	font-size: 18px;
	font-weight: 500;
	h5,
	i {
		color: #269ce0;
	}

	i {
		font-size: 30px;
		font-weight: bold;

		&:hover {
			cursor: pointer;
			color: rgb(46, 56, 77);
		}
	}
}

.mdi-chevron-left.close {
	color: #269ce0 !important;
}

.managementNote {
	background-color: #ffeaa7;
}

.leading-colour {
	color: #0083c9;
}

.leading-colour-background {
	background: #0083c9 !important;
}

.hamburger {
	outline: 0 !important;
}

.Modal-content {
	overflow: scroll;
	overflow-x: hidden;
}

.Modal-header {
	margin-bottom: 1px;
}

.Modal-actions {
	border-top: 1px solid #269ce0;
	position: absolute !important;
	bottom: 0px !important;
	left: 0px !important;
	right: 0px !important;
}

.desktopOnly {
	display: none;
}

.ListItem {
	min-height: 54px;
	margin: 5px auto;
	display: flex;
	align-items: center;
	padding: 10px;

	i {
		margin-right: 10px;
	}

	&:hover {
		background-color: #f5f6fb;
		border-radius: 3px;
		color: #2e384d;
	}
}

@media (min-width: 961px) {
	.desktopOnly {
		display: block;
	}
}

.selectAll {
	margin-bottom: 10px;

	.mdi {
		margin-right: 10px;
		color: #f7f7f7;

		&:hover {
			color: #3e4676;
		}

		&.active {
			color: #408ff8;
		}
	}

	.mdi-minus-circle {
		color: #cccccc;

		&:hover {
			color: #f05e63;
		}
	}
}

.CustomCheckbox {
	label {
		border: solid 1px #1b1d29;
		padding: 5px 15px;
		border-radius: 3px;
		font-size: 12px;
	}

	&:hover,
	&.checked {
		cursor: pointer;

		label {
			cursor: pointer;
			background: #1b1d29;
			color: #fff;
		}
	}
}

.__react_component_tooltip {
	z-index: 9999 !important;
}

.cdk-overlay-container {
	z-index: 999999 !important;
}

.skills .GridViewWithRightPanel {
	min-height: unset;
}

.skills .rt-td {
	padding: 0;
}

.blink {
	animation: blink-animation 1s steps(5, start) infinite;
	-webkit-animation: blink-animation 1s steps(5, start) infinite;
}

.NotificationsControl {
	width: 100%
}

.automations {
	.editor-wrapper {
		&:hover {
			border: 2px solid transparent !important;
		}

		.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
			right: 0
		}
	}
}

@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

@-webkit-keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

.video-react .video-react-video {
	transform: none;
}

@media (max-width: 997px) {
	.CustomCheckbox {
		width: 100%;

		label {
			width: 100%;
		}
	}
}

//* Utility classes *//

.flex-center-align {
	display: flex;
	align-items: center;
}

.MuiAutocomplete-popper {
	z-index: 9999 !important;
}

/* Tooltip container */
.my-tooltip {
	position: relative;
	display: inline-block;
	/* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltiptext {
	visibility: hidden;
	width: 300px;
	padding: 20px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.my-tooltip:hover .tooltiptext {
	visibility: visible;
}

.measureInputHeightElement::-webkit-outer-spin-button,
.measureInputHeightElement::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}