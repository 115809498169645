//custom option box with variation
.option-box-column {
  display: inline-block;
  input[type="checkbox"] ,
  input[type="radio"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  label {
    position: relative;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    transition: all ease 0.2s;
    box-shadow: $box-shadow;
    cursor: pointer;
    background-color: $white;

    &:after,
    &:before {
      position: absolute;
      color: $gray-300;
      left: 50%;
      top: 10px;
      font-family: $icon-font;
      font-size: 1.5rem;
      margin-left: -0.75rem;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    &:after {
      content: $icon-circle;
    }
    &:before {
      content: $icon-circle;
      opacity: 0;
      transform: scale(0);
      transition: all ease 0.2s;
    }
    .radio-content {
      text-align: center;
      margin-top: 2rem;
      display: block;
      .radio-highlight {
        display: block;
        transition: all ease 0.2s;
        background-color: $gray-300;
        color: $gray-700;
        padding: 15px;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

    }

  }

  input[type="checkbox"]:checked + label ,
  input[type="radio"]:checked + label {
    &:after {
      opacity: 0;
    }
    &:before {
      content: "\F5e0";
      color: $success;
      opacity: 1;
      transform: scale(1);
    }

  }
  input[type="checkbox"]:checked + label ,
  input[type="radio"]:checked + label {
    .radio-content {
      .radio-highlight {
        background-color: $primary;
        color: $white;

      }

    }
  }

}